import { useEffect, useRef, useState } from "react";
// import React from 'react';
import "./index.scss";
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const Home = (): JSX.Element => {


  const [ordIndex, setOrdIndex] = useState<number>(1)
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {

    const details = gsap.utils.toArray(".desktopContentSection:not(:first-child)")
    const photos = gsap.utils.toArray(".desktopPhoto:not(:first-child)")


    gsap.set(photos, { yPercent: 101 })

    const allPhotos = gsap.utils.toArray(".desktopPhoto")


    // create
    // let mm = gsap.matchMedia();

    // add a media query. When it matches, the associated function will run
    // mm.add("(min-width: 600px)", () => {

    // this setup code only runs when viewport is at least 600px wide

    ScrollTrigger.create({
      trigger: ".project",
      start: "top top",
      end: "bottom bottom",
      pin: ".right",
      // markers: true
    })

    details.forEach((detail, index) => {
      console.log('%c🀄︎ index', 'color: #ffa640; font-size: 20px;', index);
      //@ts-ignore
      let headline = detail.querySelector("h1");
      let animation = gsap.timeline()
        //@ts-ignore
        .to(photos[index], { yPercent: 0 })
        //@ts-ignore
        .set(allPhotos[index], { autoAlpha: 0 });

      let prevScroll = window.pageYOffset || document.documentElement.scrollTop;
      let currentScroll;
      ScrollTrigger.create({
        trigger: headline,
        start: "top 60%", // 修改起始位置为 60%
        end: "top 40%", // 修改结束位置为 40%
        animation: animation,
        scrub: true,
        // markers: true,
        onEnter: () => {
          //@ts-ignore
          const ordValue = detail.getAttribute("data-ord");
          setOrdIndex(parseInt(ordValue, 10));
          //@ts-ignore
          // gsap.set(allPhotos[index], { autoAlpha: 0.5 });
        },

        onUpdate: (self) => {
          // Get the current scroll position
          currentScroll = window.pageYOffset || document.documentElement.scrollTop;
          // Compare with the previous scroll position
          if (currentScroll > prevScroll) {
            // Scrolling down
            // //@ts-ignore
            // gsap.set(allPhotos[index], { autoAlpha: 0.5 });
            // //@ts-ignore
            // gsap.set(allPhotos[index + 1], { autoAlpha: 1 });



          } else {
            // Scrolling up
            //@ts-ignore
            // gsap.set(allPhotos[index], { autoAlpha: 1 });
            //@ts-ignore
            // gsap.set(allPhotos[index + 1], { autoAlpha: .5 });
            //@ts-ignore
            const ordValue = allPhotos[index].getAttribute("data-ord");
            // console.log('%c🀁 3333333333', 'color: #d90000; font-size: 20px;', ordValue);

            setOrdIndex(parseInt(ordValue, 10));

          }


          // Update the previous scroll position
          prevScroll = currentScroll;
        },

      });
    });




    return () => { // optional
      // custom cleanup code here (runs when it STOPS matching)
      console.log("mobile")
    };
    // });

  }, []);

  useEffect(() => {
    // To ensure that the scroll position is set at the top when the page loads
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo(0, 0);
    }
  }, []);


  return (
    <>
      <div className="page" ref={scrollContainerRef}>
        <div className="header" data-aos="fade-in">
          <div className="logo">
            <img src={require('../../assets/logo.png')} alt="" />
          </div>
          <div className="nav">
            {/* <div className="item langu">
              <img src={require('../../assets/changLanguage.png')} alt="" />
            </div> */}
            <button className="contact"
              onClick={() => {
                const dom = document.getElementById('footer');
                if (dom) {
                  dom.scrollIntoView({ behavior: "smooth", });
                }
              }}
            >Contact Us</button>
          </div>
        </div>
        <div className="banner">
          <div className="logo" data-aos="fade-up">
            <img src={require('../../assets/banner-icon.png')} alt="" width={'100%'} />
          </div>
          <h2 data-aos="fade-up">The Smart Contract Expansion
            Dock Utilizing DOT as Gas Fee</h2>
          <a className="download" href={require('../../assets/pdfs/MAGLightpaper.pdf')} download> Introducing MCP</a>
        </div>

        <div className={['project'].join(' ')}>


          <div className="left">
            <div className="desktopContent">
              <div data-ord='1' className="desktopContentSection">
                <h1>1</h1>

              </div>
              <div data-ord='2' className="desktopContentSection">
                <h1>2</h1>

              </div>
              <div data-ord='3' className="desktopContentSection">
                <h1>3</h1>

              </div>
              <div data-ord='4' className="desktopContentSection">
                <h1>4</h1>

              </div>
              <div data-ord='5' className="desktopContentSection item5">
                <h1>5</h1>

              </div>

            </div>
          </div>
          <div className="right">
            <h2 className='projecttitle' data-aos="fade-in">Project Characteristics</h2>
            <ul className="ordList">
              <li className={ordIndex === 1 ? 'active' : ''}></li>
              <li className={ordIndex === 2 ? 'active' : ''}></li>
              <li className={ordIndex === 3 ? 'active' : ''}></li>
              <li className={ordIndex === 4 ? 'active' : ''}></li>

              <li className={ordIndex === 5 ? 'active' : ''}></li>
            </ul>

            <div className="project-list desktopPhotos">
              <div data-ord='1' className="item projectItem desktopPhoto">
                <div className="ord active">
                  01
                </div>
                <div className="content">
                  <h3>DOT as the native token</h3>
                  <p> Magnet operates as a token-less chain, with all token functionalities carried out by DOT obtained through XCM.</p>
                </div>
              </div>

              <div data-ord='2' className="item projectItem desktopPhoto">
                <div className="ord">
                  02
                </div>
                <div className="content">
                  <h3>Flexible and fast access through PAYG</h3>
                  <p> Magnet utilizes the PAYG model to quickly and flexibly order blockspace and access the Polkadot network.</p>
                </div>
              </div>

              <div data-ord='3' className="item projectItem desktopPhoto">
                <div className="ord">
                  03
                </div>
                <div className="content">
                  <h3>Sustainable operation through gas fee revenue</h3>
                  <p> Magnet collects gas fees on its network and uses them to purchase the necessary blocks. The difference between the actual ordering price and the obtained gas fees becomes the Magnet's revenue, which will be allocated to the Polkadot treasury, system reserves, collator incentives, and project operations.</p>
                </div>
              </div>

              <div data-ord='4' className="item projectItem desktopPhoto">
                <div className="ord">
                  04
                </div>
                <div className="content">
                  <h3>Support for EVM and WASM</h3>
                  <p> Initially, Magnet supports EVM to rapidly acquire and accumulate users. Later, it will also support WASM to prepare for the future high-performance smart contract market.</p>
                </div>
              </div>

              <div data-ord='5' className="item projectItem desktopPhoto">
                <div className="ord">
                  05
                </div>
                <div className="content">
                  <h3>Governance delegated to DOT holders</h3>
                  <p> Magnet's on-chain governance is entrusted to DOT holders, making DOT as the governance token of Magnet.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="moreList">
          <div className="item" data-aos="fade-up">
            <div className="ord" >06</div>
            <div className="content">
              <h3>Enabling rapid parallel deployment through Polkadot Network
              </h3>
              <p>Due to Polkadot's Substrate architecture and Coretime design, Magnet can achieve efficient and cost-effective network access, enabling platform service scalability through parallel deployment.
              </p>

            </div>
            <div className="img"></div>
            <img src={require('../../assets/pr7.gif')} alt="" className="bg" />
          </div>
          <div className="item" data-aos="fade-up">
            <div className="ord">07</div>
            <div className="content">
              <h3>Collaboration with Tanssi Network for rapid development</h3>
              <p>Magnet leverages the development tools provided by Tanssi to enhance efficiency and enable quick implementation of logic. It also becomes a ContainerChain of the Tanssi Network, utilizing Tanssi Network's collators to assist in network formation. With the support of Tanssi and Polkadot, Magnet will achieve stable and secure operations, providing reliable services.</p>
            </div>
            <div className="img"></div>
            <img src={require('../../assets/pr6.gif')} alt="" className="bg" />
          </div>
          <div className="item before" data-aos="fade-up">
            <div className="img"></div>
            <div className="ord">08</div>
            <div className="content">
              <h3>Inter docking station communication via XCM</h3>
              <p>Communication between different docking stations will be supported by XCM V3. The platform will develop XCM auxiliary tools to auxiliary cross-platform contract invocation.</p>
            </div>
            <img src={require('../../assets/pr8.gif')} alt="" className="bg" />
          </div>
          <div className="item before" data-aos="fade-up">
            <div className="img"></div>
            <div className="ord">09</div>
            <div className="content">
              <h3>Expansion mechanism utilizing PAYG and class-Rollup</h3>
              <p>With the support of trusted collators in Tanssi, Magnet combines the PAYG model to achieve a highly trusted class Rollup model. The containerchain can pre-generate multiple blocks and then order blockspace in a single batch, enabling fast transaction execution and improving blockspace utilization efficiency.</p>
            </div>
            <img src={require('../../assets/pr9.gif')} alt="" className="bg" />
          </div>
        </div>
        <div className="footer" id="footer" data-aos="fade-up">
          <div className="content">
            <div className="left">
              <div className="logo">
                <img src={require('../../assets/footer-logo.png')} alt="" height={'100%'} />
              </div>
              <div className="socialList">
                <div className="item">
                  <a href="https://twitter.com/Magnet20239" target={'_blank'} rel="noreferrer" >
                    <img src={require('../../assets/twitter.png')} alt="" />
                  </a>
                </div>
                <div className="item">
                  <a href="https://t.me/+aep298N0KXUwZTM1" target={'_blank'} rel="noreferrer" >
                    <img src={require('../../assets/tg.png')} alt="" />
                  </a>
                </div>
                <div className="item">
                  <a href="mailto:magport@magport.io" target={'_blank'} rel="noreferrer" >
                    <img src={require('../../assets/email.png')} alt="" />
                  </a>
                </div>
                {/* <div className="item">
              <a href="#">
                <img src={require('../../assets/discord.png')} alt="" />
              </a>
            </div>
            <div className="item">
              <a href="#">
                <img src={require('../../assets/github.png')} alt="" />
              </a>
            </div> */}
              </div>
            </div>
            <div className="right">
              <p>cooperative</p>
              <div className="list">
                <div className="item">
                  <img src={require('../../assets/tanssi_logo.png')} alt="" />
                </div>
                <div className="item">
                  <img src={require('../../assets/polkadot_logo.png')} alt="" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="nav">
            <a href="#" className="item">terms of use</a>
            <a href="#" className="item">privacy policy</a>
          </div> */}
          <p>Copyright @ 2023 Magnet</p>

        </div>


        {/* <Footer></Footer> */}
      </div>
    </>
  );
}

export default Home;
