const namespace = `magnet`;

export const evmKeyString = "evm_import_token";
export const substrateKeyString = "substrate_import_token";

export class StorageUtil {
  static setItem(key: string, val: any) {
    let storage = this.getStorage();
    storage[key] = val;
    window.localStorage.setItem(
      `${namespace}-${process.env.REACT_APP_MY_VARIABLE}`,
      JSON.stringify(storage)
    );
  }

  static getItem(key: string) {
    return this.getStorage()[key];
  }

  static getStorage() {
    return JSON.parse(
      window.localStorage.getItem(
        `${namespace}-${process.env.REACT_APP_MY_VARIABLE}`
      ) || "{}"
    );
  }

  static clearItem(key: string) {
    let storage = this.getStorage();
    delete storage[key];
    window.localStorage.setItem(
      `${namespace}-${process.env.REACT_APP_MY_VARIABLE}`,
      JSON.stringify(storage)
    );
  }

  static clearAll() {
    window.localStorage.clear();
  }
}
