/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import { isMobile } from './libs/userAgent';
import MobileHome from './pages/mobileHome';
import ListTewst from './pages/test';
import ListView from './pages/List';
import Bridges from './pages/bridges';
import MetaMaskIntegration from './pages/bridges/test';
import SignDemo from './pages/bridges/demo';


function App() {






  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={isMobile() ? <MobileHome /> : <Home />} />
          <Route path='/test' element={<ListTewst></ListTewst>} />
          <Route path='/assets' element={<ListView></ListView>} />
          <Route path='/bridges' element={<Bridges></Bridges>} />
          <Route path='/222' element={<MetaMaskIntegration></MetaMaskIntegration>} />
          <Route path='sign' element={<SignDemo></SignDemo>} />


          <Route path="*" element={<Navigate to="/" replace={true} />} />

        </Routes>

        <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
      </Router>
    </>
  );
}

export default App;
