import { ApiPromise, WsProvider } from "@polkadot/api";
import { web3Accounts, web3Enable, web3FromSource } from "@polkadot/extension-dapp";
import { stringToHex } from "@polkadot/util";
import { Button } from "antd";
import _ from "lodash";
import { useState } from "react";

const SignDemo = (): JSX.Element => {

  const [SUBAccounts, setSUBAccounts] = useState<any[]>([])
  const [selectSubWallet, setSellectSunWallet] = useState<any>()
  const [signatureData, setSignatureData] = useState<any>()

  const connectToApi8854 = async () => {
    const provider = new WsProvider('wss://appmag.magport.io/ ');
    return await ApiPromise.create({ provider });
  };

  //连接substrate wallet
  const connectWallet = async () => {


    try {
      const extensions = await web3Enable("Data tranding market");
      if (extensions.length === 0) { // 未安装波卡钱包插件
        console.log("Please create cess-hacknet chain account.");
        return;
      }
      let allAccounts = await web3Accounts(); // 获取所有波卡钱包账户

      const filteredAccounts = _.filter(allAccounts, (item) => item.type !== 'ethereum');
      console.log('%c🀂 filteredAccounts', 'color: #d90000; font-size: 20px;', filteredAccounts, selectSubWallet);
      setSUBAccounts(filteredAccounts)
      setSellectSunWallet(filteredAccounts[0])

      const api = await connectToApi8854();

      // @ts-ignore
      const { data: balance } = await api.query.system.account(filteredAccounts[0].address);
      console.log(`账户余额: ${balance.free.toHuman()}`);

      if (allAccounts.length === 0) { //未创建钱包账户
        console.log("Please create account fisrt.");
        return;
      }
    } catch (error) {
      console.error('Error sending transaction:', error);
    }


    // (await api).disconnect();
  }


  const signFun = async (addressInfo: { meta: { source: string; }; address: any; }) => {
    const injector = await web3FromSource(addressInfo.meta.source);
    const signRaw = injector?.signer?.signRaw;

    if (!!signRaw) {
      // after making sure that signRaw is defined
      // we can use it to sign our message
      const { signature } = await signRaw({
        address: addressInfo.address,
        data: stringToHex('message to sign'),
        type: 'bytes'
      });
      setSignatureData(signature)

      console.log('%c🀃 ', 'color: #ffa640; font-size: 20px;', signature);
    }
  }

  return (
    <>
      {
        SUBAccounts.length > 0
          ?
          <>
            {
              SUBAccounts.map((element, index) => (
                <div key={index} style={{
                  marginBottom: 15
                }}>
                  <p style={{
                    fontSize: 16
                  }}>{element.address}</p>
                  <Button
                    onClick={() => {
                      signFun(element)
                    }}
                  >
                    sign-{index + 1}
                  </Button>
                </div>
              ))
            }
            {!!signatureData ? <p style={{
              width: '100%',
              fontSize: 16
            }}>signature:{signatureData}</p> : ''}
          </>
          : <Button onClick={() => {
            connectWallet()
          }}>connect wallet</Button>
      }

    </>
  );
}

export default SignDemo;