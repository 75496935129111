import { LoadingOutlined } from '@ant-design/icons';
import { ApiPromise, WsProvider } from '@polkadot/api';
import { web3Enable, web3Accounts } from '@polkadot/extension-dapp';
import { Tabs, Popover, Button } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import gsap from 'gsap';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import getDotPrice from '../../api';
import { ImportTokenModel } from '../../models/import_token';
import { StorageUtil, evmKeyString, substrateKeyString } from '../../libs/local_storage';
import { TokenABIData } from '../../libs/token_abi';
import './index.scss'


const ethWeb3 = new Web3(new Web3.providers.WebsocketProvider('wss://magnet-rpc.magport.io/ws '));
// const ethAddress = '0xE30De18DA91C955901C83d3Ed9315068Fe4fa3D3'; // 以太坊地址

// const providerWsURL1 = 'wss://app.magport.io/'; // 第一个 dot-relaychian 节点 URL
// const providerWsURL2 = 'wss://magnet-rpc.magport.io/ws'; // 第二个 dot-magnet 节点 URL
// const accountAddress1 = '5HfsnrSkC1ujtNSAhpH3LrUNTdUqfBKCTQGbAxWZsoDKcNbp'; // dot-relaychian 地址
// const accountAddress2 = '5HfsnrSkC1ujtNSAhpH3LrUNTdUqfBKCTQGbAxWZsoDKcNbp'; // dot-magnet 地址（如果不同，请替换）


const ListView = (): JSX.Element => {

  // 断开metamask钱包
  const [disconnect, setDisconnect] = useState(false)
  // 钱包余额
  const [relaychianBalance, setRelaychianBalance] = useState<any>(0);
  const [relaychianBalanceLoading, setRelaychianBalanceLoading] = useState(true)
  // metamask钱包地址
  const [accountAddress, setAccountAddress] = useState("");
  const [selectSubWallet, setSelectSunWallet] = useState<any>()
  const [activeEVMButton, setActiveEVMButton] = useState(null);
  // tab序号
  const [selectTabsOrd, setSelectTabsOrd] = useState('1')
  const [showWalletOpen, setShowWalletOpen] = useState(false)
  const [SUBAccounts, setSUBAccounts] = useState<any[]>([])
  const [activeSubButton, setActiveSubButton] = useState(null);

  const [MagnetBalance, setMagnetBlance] = useState(0)
  const [EVMBalance, setEVMBlance] = useState(0)
  const [MagnetBalanceLoading, setMagnetBalanceLoading] = useState(true)
  const [EVMBalanceLoading, setEVMBalanceLoading] = useState(true)
  const showWalletModel = () => {
    gsap.fromTo('.wallet_model', { opacity: 0, zIndex: -19999, display: 'none' }, { opacity: 1, zIndex: 1001, display: 'block', duration: .3 })
    setShowWalletOpen(true)
  }
  const closeWalletModel = () => {
    gsap.fromTo('.wallet_model', { opacity: 1, zIndex: 1001, display: 'block' }, { opacity: 0, zIndex: -9999, display: 'none', duration: .3 })
    setShowWalletOpen(false)
  }

  // 获取meatmask钱包余额
  async function getAccount() {
    // @ts-ignore
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];

    return account;
  }

  // 查询和显示ERC-20代币的基本信息和余额
  //@ts-ignore
  // const queryTokenInfoAndBalance = async (tokenAddress: any) => {
  //   const ethWeb3 = new Web3(new Web3.providers.WebsocketProvider('wss://magnet-rpc.magport.io/ws'));
  //   const tokenABI = TokenABIData;
  //   const tokenContract = new ethWeb3.eth.Contract(tokenABI, tokenAddress);
  //   try {
  //     const balance = await tokenContract.methods.balanceOf(accountAddress).call();
  //     console.log('%c🀅 balance', 'color: #8c0038; font-size: 20px;', balance);

  //     // const name: any = await tokenContract.methods.name().call();
  //     // const symbol: any = await tokenContract.methods.symbol().call();
  //     // console.log('%c🀁 symbol', 'color: #408059; font-size: 20px;', symbol);
  //     // const decimals = await tokenContract.methods.decimals().call();
  //     //@ts-ignore
  //     // const balance = await tokenContract.methods.balanceOf(accountAddress).call();
  //     // @ts-ignore
  //     // const adjustedBalance = ethWeb3.utils.fromWei(balance, 'ether').toFixed(3);
  //     // console.log(`小数位数: ${decimals}`);
  //     // console.log(`${symbol} 余额: ${adjustedBalance}`);

  //     // setTokenSymbolValue(name)
  //   } catch (error: any) {
  //     console.error('查询以太坊余额时发生错误:', error);
  //     toast.dismiss();
  //     toast.error(error.message, {
  //       position: "top-right",
  //       autoClose: 7000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //   }
  // }

  const initImportTokenPrice = useCallback(async () => {
    const tokenABI = TokenABIData;

    // const substrateListFormStorage: ImportTokenModel[] = StorageUtil.getItem(substrateKeyString);
    // if (substrateListFormStorage) {
    //   let substrateList = substrateListFormStorage.map((element) => {
    //     return {
    //       symbol: element.symbol,
    //       address: element.address,
    //       isLoading: true,
    //       price: 0,
    //       BalanceWei: ''
    //     };
    //   });
    //   if (substrateList.length > 0) {
    //     for (var i = 0; i < substrateList.length; i++) {
    //       const tokenContract = new ethWeb3.eth.Contract(tokenABI, substrateList[i].address);
    //       try {
    //         const name = await tokenContract.methods.name().call();
    //         console.log('%c🀄︎ name', 'color: #99adcc; font-size: 20px;', name);
    //         const symbol: any = await tokenContract.methods.symbol().call();
    //         const decimals = await tokenContract.methods.decimals().call();
    //         console.log('%c🀃 decimals', 'color: #f279ca; font-size: 20px;', decimals);
    //         //@ts-ignore
    //         const balance = await tokenContract.methods.balanceOf(accountAddress).call();
    //         //@ts-ignore
    //         const adjustedBalance: any = ethWeb3.utils.fromWei(balance, 'ether');
    //         substrateList[i].isLoading = false;
    //         substrateList[i].price = adjustedBalance;
    //         substrateList[i].BalanceWei = symbol;
    //       } catch (error) {
    //         console.error('查询以太坊余额时发生错误:', error);
    //       }
    //     }
    //   }
    // }
    const evmListFormStorage: ImportTokenModel[] = StorageUtil.getItem(evmKeyString);
    if (evmListFormStorage) {
      let evmList = evmListFormStorage.map((element) => {
        return {
          symbol: element.symbol,
          address: element.address,
          isLoading: true,
          price: 0,
          BalanceWei: ''
        };
      });

      if (evmList.length > 0) {
        const list: any[] = [];
        for (var k = 0; k < evmList.length; k++) {
          const tokenContract = new ethWeb3.eth.Contract(tokenABI, evmList[k].address);
          console.log('%c🀃 tokenContract', 'color: #ffa280; font-size: 20px;', tokenContract);
          try {
            // const name = await tokenContract.methods.name().call();
            // console.log('%c🀆 name', 'color: #514080; font-size: 20px;', name);
            // const symbol: any = await tokenContract.methods.symbol().call();
            // const decimals = await tokenContract.methods.decimals().call();
            // console.log('%c🀀 decimals', 'color: #00ff88; font-size: 20px;', decimals);
            // //@ts-ignore
            const balance = await tokenContract.methods.balanceOf(accountAddress).call();
            //@ts-ignore
            const adjustedBalance: any = ethWeb3.utils.fromWei(balance, 'ether');
            console.log('%c🀆 adjustedBalance initImportTokenPriceinitImportTokenPrice', 'color: #99614d; font-size: 20px;', adjustedBalance);
            evmList[k].isLoading = false;
            evmList[k].price = adjustedBalance;
            evmList[k].BalanceWei = evmList[k].symbol;
            list.push(evmList[k])
          } catch (error: any) {
            list.push(evmList[k])
            console.error('查询以太坊余额时发生错误:', error);
          }
          setEvmTokens(list)
        }
      }
    }
  }, [accountAddress])

  const initImportTokens = async () => {
    const substrateList: ImportTokenModel[] = StorageUtil.getItem(substrateKeyString);
    if (substrateList) {
      const list: any[] = [];
      substrateList.forEach((element) => {
        list.push({
          symbol: element.symbol,
          address: element.address,
          isLoading: true,
          price: 0,
        });
      });
      setSubstrateTokens(list);
    }
    const evmList: ImportTokenModel[] = StorageUtil.getItem(evmKeyString);
    if (evmList) {
      const list: any[] = [];
      evmList.forEach((element) => {
        list.push({
          symbol: element.symbol,
          address: element.address,
          isLoading: true,
          price: 0,
        });
      });
      setEvmTokens(list);
    }

  };

  const connectButtonOnClick = () => {
    if (
      //@ts-ignore
      typeof window !== "undefined" && typeof window.ethereum !== "undefined"
    ) {
      getAccount().then((response) => {
        setAccountAddress(response);
        queryEthBalance(response);
        initImportTokenPrice();
        setDisconnect(true)
      });
    } else {
      console.log("error");
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //@ts-nocheck
  useEffect(() => {
    const handleSUBButtonClicka = (buttonId: any) => {
      setActiveSubButton(buttonId);
      connectWallet()
      closeWalletModel()
    };
    handleSUBButtonClicka(1)
  }, [])


  //连接substrate wallet
  const connectWallet = async () => {


    try {
      const extensions = await web3Enable("Data tranding market");
      if (extensions.length === 0) { // 未安装波卡钱包插件
        return;
      }
      let allAccounts = await web3Accounts(); // 获取所有波卡钱包账户

      const filteredAccounts = _.filter(allAccounts, (item) => item.type !== 'ethereum');
      setSUBAccounts(filteredAccounts)
      setSelectSunWallet(filteredAccounts[0])

      if (allAccounts.length === 0) { //未创建钱包账户
        return;
      }
    } catch (error) {
      console.error('Error sending transaction:', error);
    }


    // (await api).disconnect();
  }

  const handleEVMButtonClick = (buttonId: any) => {
    setActiveEVMButton(buttonId);
    connectButtonOnClick()
    closeWalletModel()
  };

  const [dotPriceVal, setDotPriceVal] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dotPrice = await getDotPrice();
        setDotPriceVal(dotPrice)
        // 在这里使用 dotPrice 进行其他操作
      } catch (error: any) {
        // 错误处理
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };

    fetchData();
  }, []);


  const queryEthBalance = async (address: any) => {
    try {
      const balance = await ethWeb3.eth.getBalance(address);


      //@ts-ignore
      setEVMBlance(Number(ethWeb3.utils.fromWei(balance, 'ether')).toFixed(3))
      setEVMBalanceLoading(false)
    } catch (err) {
      console.error('查询以太坊余额时发生错误:', err);
    }
  };

  const queryPolkadotBalance = async (providerWsURL: string, accountAddress: string, type: string) => {
    const provider = new WsProvider(providerWsURL);
    const api = await ApiPromise.create({ provider });

    try {
      //@ts-ignore
      const { data: balance } = await api.query.system.account(accountAddress);

      const balanceInPlanck = Number(balance.free.toString()); // 获取 BigInt 类型的余额表示

      let balanceToSet;
      const DOTtoPlanck = type === 'magnet' ? 1000000000000000000 : 1000000000000; // 根据 type 设置 DOTtoPlanck

      const balanceInDOT = (balanceInPlanck / DOTtoPlanck).toString(); // 将 BigInt 转换为字符串

      // 将 BigInt 转换为 JavaScript 中的数字类型，并保留 5 位小数
      const balanceInNumber = Number(balanceInDOT);
      const roundedBalance = balanceInNumber.toFixed(5);

      if (type === 'magnet') {
        balanceToSet = Number(roundedBalance).toFixed(3);
      } else {
        balanceToSet = Number(roundedBalance).toFixed(3);
      }

      // 调用设置余额的函数
      if (type === 'magnet') {
        //@ts-ignore
        setMagnetBlance(balanceToSet);
        setMagnetBalanceLoading(false)
      } else {
        setRelaychianBalance(balanceToSet);
        setRelaychianBalanceLoading(false)
      }

    } catch (err: any) {
      toast.error(err.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }

    provider.disconnect();
  };


  useEffect(() => {
    // 监听钱包地址变化
    const checkAccountChange = async () => {
      // 使用 MetaMask 提供的 provider
      //@ts-ignore
      const web3 = new Web3(window.ethereum);

      // 获取当前账户地址
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];

      // 检查地址是否发生变化
      if (address !== accountAddress) {
        // 处理地址变化的逻辑
        setAccountAddress(address);
        queryEthBalance(address);

      }

      try {
        // 请求用户授权连接到 MetaMask
        //@ts-ignore
        await window.ethereum.enable();

        // 连接到指定网络（例如 Rinkeby）
        switchToCustomNetwork();

      } catch (error) {
        console.error(error);
      }
      setDisconnect(true)
    };
    // 初始化监听
    checkAccountChange();

    // 监听 MetaMask 账户变化
    //@ts-ignore
    window.ethereum.on('accountsChanged', checkAccountChange);

    // 清理函数
    return () => {
      // 移除监听
      //@ts-ignore
      window.ethereum.off('accountsChanged', checkAccountChange);
    };
  }, [accountAddress]);

  useEffect(() => {
    if (selectSubWallet) {

      queryPolkadotBalance('wss://rococo-rpc.polkadot.io', selectSubWallet.address, 'relaychian');
      queryPolkadotBalance('wss://magnet-rpc.magport.io/ws', selectSubWallet.address, 'magnet');
    }
  }, [selectSubWallet])


  useEffect(() => {
    initImportTokenPrice();

  }, [initImportTokenPrice])

  useEffect(() => {
    // 连接到指定网络（例如 Rinkeby）
    switchToCustomNetwork();
  })

  const switchToCustomNetwork = async () => {
    if (!(window as any).ethereum) {
      console.error('MetaMask extension not detected');
      return;
    }

    const ethereum = (window as any).ethereum;
    const chainId = process.env.REACT_APP_CHAINID;
    console.log('%c🀆 chainId', 'color: #86bf60; font-size: 20px;', chainId);

    try {
      // 请求用户授权连接 MetaMask
      await ethereum.request({ method: 'eth_requestAccounts' });

      // 切换网络
      await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: chainId, // 这是网络 ID，例如 '0x1' 代表主网
          chainName: 'Magnet Network', // 自定义网络名称
          nativeCurrency: {
            name: 'dot',
            symbol: 'dot',
            decimals: 18,
          },
          rpcUrls: ['https://magnet-rpc.magport.io/ws'], // 自定义网络的 RPC URL
        }],
      });
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }],
      });
    } catch (error) {
      console.error('Error switching network:', error);
    }
  };


  // 设置连接钱包弹窗中的波卡地址选择
  const [, setPopoverOpenAddress] = useState(false);
  const [, setPopoverOpen] = useState(false);
  // 连接evm钱包，并保存对应钱包类型
  const handleSUBButtonClick = (buttonId: any) => {
    setActiveSubButton(buttonId);
    connectWallet()
    closeWalletModel()
  };

  const popHideAddress = () => {
    setPopoverOpenAddress(false);
  };
  const popHide = () => {
    setPopoverOpen(false);
  };

  const handlepopOpenChange = (newOpen: boolean) => {
    setPopoverOpen(newOpen);
  };

  const SubWalletChange = async (type: number, item: any) => {
    try {
      setSelectSunWallet(item);



      popHideAddress();

      popHide();

      closeWalletModel();
    } catch (error) {
      console.log('%c🀅 error', 'color: #514080; font-size: 20px;', error);

    }
  }


  const PopoverContent = _.map(SUBAccounts, (item) => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px 5px',
        cursor: 'pointer'
      }}
        onClick={() => { SubWalletChange(1, item) }}
        key={item.address}
      >
        <div className="paper" >

          <svg xmlns="http:www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
              fill="#fc3900">
            </rect>
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
              fill="#f16402">
            </rect>
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
              fill="#035d55">
            </rect>
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
              fill="#f38800">
            </rect>
          </svg>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          <div className='name'>{item.meta.name}</div>
          <div className='address'>{`${item.address.slice(0, 15)}...${item.address.slice(-15)}`}</div>
        </div>
      </div>
    )
  })

  document.addEventListener('click', function (event) {
    event.stopPropagation()
    // 获取点击的目标元素
    const targetElement = event.target;
    setShowWalletOpen(false)
    // 检查点击的元素是否是 class 为 "asd" 的 div 或其子元素
    // @ts-ignore
    if (!targetElement.closest('.ConnectWallet_dropdown')) {
      // 如果不是，执行 alert(1)
      gsap.to('.wallet_model', { opacity: 0, zIndex: -19999, display: 'none' })
    }
  });

  // 导入token所选链类型
  const [importType, setImportType] = useState('Evm');
  const [isShowImport, setIsShowImport] = useState(false);

  const [substrateTokens, setSubstrateTokens] = useState<any[]>([]);
  const [evmTokens, setEvmTokens] = useState<any[]>([]);


  var importAddressRef = useRef<HTMLInputElement | null>(null);
  var importSymbolRef = useRef<HTMLInputElement | null>(null);

  // 导入Token
  const onImport = () => {
    const address = importAddressRef.current?.value;
    const symbol = importSymbolRef.current?.value;
    if (!address || (address && !address.trim()) || !symbol || (address && !symbol.trim())) {

      return;
    }

    if (importType === 'Evm') {
      let evmTokens: ImportTokenModel[] = StorageUtil.getItem(evmKeyString);

      if (evmTokens) {
        const dateIndex = evmTokens.findIndex((element) => element.address === address);
        if (dateIndex < 0) {
          evmTokens.push({
            address,
            symbol,
          });
        } else {
          toast.error(`token has been imported`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      } else {
        evmTokens = [];
        evmTokens.push({
          address,
          symbol,
        });

      }
      StorageUtil.setItem('evm_import_token', evmTokens);
    } else {
      let substrateTokens: ImportTokenModel[] = StorageUtil.getItem(substrateKeyString);
      if (substrateTokens) {
        const dateIndex = substrateTokens.findIndex((element) => element.address === address);
        if (dateIndex >= 0) {
          substrateTokens.push({
            address,
            symbol,
          });
        }
      } else {
        substrateTokens = [];
        substrateTokens.push({
          address,
          symbol,
        });
      }
      StorageUtil.setItem('substrate_import_token', substrateTokens);
    }

    setIsShowImport(false);
    initImportTokenPrice()
    // @ts-ignore
    importAddressRef.current.value = '';
    // @ts-ignore
    importSymbolRef.current.value = '';
  };


  useEffect(() => {
    initImportTokens();
  }, []);
  // useEffect(() => {
  //   queryEthBalance();

  // }, []);

  const [TokenSymbolValue, setTokenSymbolValue] = useState('');
  return (
    <>
      <div className="listview">
        <div className="header">
          <div className="logo">
            <img src={require('../../assets/logo_w.png')} alt="" />
          </div>
          <div className="nav">
            <a href="/assets" className='active'>Assets</a>
            <a href="/bridges">Parachain Bridges</a>
          </div>
          {
            disconnect
              ? <div className="walletInfo" >
                <div className="m-4081bf90 mantine-Group-root">
                  <div className="paper" >
                    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="100%" width="100%">
                      <rect x="0" y="0" rx="0" ry="0" height="35" width="35" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                        fill="#fc3900">
                      </rect>
                      <rect x="0" y="0" rx="0" ry="0" height="35" width="35" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                        fill="#f16402">
                      </rect>
                      <rect x="0" y="0" rx="0" ry="0" height="35" width="35" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                        fill="#035d55">
                      </rect>
                      <rect x="0" y="0" rx="0" ry="0" height="35" width="35" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                        fill="#f38800">
                      </rect>
                    </svg>
                  </div>
                  <div className="address">
                    <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                      {!!accountAddress ? `${accountAddress.slice(0, 10)}...${accountAddress.slice(-5)}` : "Not connected"}
                    </p>
                    <button className='Disconnect'>{!accountAddress ? 'Disconnect' : 'Connected'}</button>
                  </div>
                  <span className="m-80f1301b mantine-Button-inner">
                    <span className="m-811560b9 mantine-Button-label"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showWalletOpen) {
                          closeWalletModel()
                        } else {
                          showWalletModel()
                        }
                      }}
                    >
                      <svg
                        stroke="#fff"
                        fill="#fff"
                        strokeWidth={0}
                        viewBox="0 0 512 512"
                        className="ConnectWallet_openWalletIcon__s1LVr"
                        height=".4em"
                        width=".4em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="none"
                          strokeLinecap="square"
                          strokeMiterlimit={10}
                          strokeWidth={48}
                          d="M112 184l144 144 144-144"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
              </div>
              : <div className="wallet">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (showWalletOpen) {
                      closeWalletModel()
                    } else {
                      showWalletModel()
                    }
                  }}
                >
                  <img src={require('../../assets/wallet.png')} alt="" />
                  <span>Connect wallet</span>
                </button>
              </div>
          }
        </div>
        <div className="main">
          <div className="content">
            <div className="search">
              <button
                onClick={(e) => {
                  setIsShowImport(true);
                }}
              >import token</button>
              <div className="search_box">
                <div className="icon-search">
                  <img src={require('../../assets/icon-search.png')} alt="" />
                </div>
                <input type="text" />
              </div>
            </div>
            <div className="listBox">
              <ul className="tbheader">
                <li className='token'>Token</li>
                <li className='balance'>Balance</li>
                <li className='price'>Token price</li>
                <li className='value'>Total value</li>
                <li className='actions'>Actions</li>
              </ul>
              <div className="tbbody">
                <div className="item">
                  <div className='token'>
                    <img className='collection' src={require('../../assets/collection.png')} alt="" />
                    <img className='token_img' src={require('../../assets/dot_logo.png')} alt="" />
                    <span>DOT-Relaychain</span>
                  </div>
                  <div className='balance'>{relaychianBalanceLoading ? <LoadingOutlined /> : relaychianBalance}DOT</div>
                  <div className='price'>${dotPriceVal}</div>
                  <div className='value'>${(relaychianBalance * dotPriceVal).toFixed(5)}</div>
                  <div className='actions'>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showWalletOpen) {
                          closeWalletModel()
                        } else {
                          showWalletModel()
                        }
                      }}>Switch Account</Button>
                  </div>
                </div>
                <div className="item">
                  <div className='token'>
                    <img className='collection' src={require('../../assets/collection.png')} alt="" />
                    <img className='token_img' src={require('../../assets/dot_logo.png')} alt="" />
                    <span>DOT-Magnet</span>
                  </div>
                  <div className='balance'>{MagnetBalanceLoading ? <LoadingOutlined /> : MagnetBalance}DOT</div>
                  <div className='price'>${dotPriceVal}</div>
                  <div className='value'>${(MagnetBalance * dotPriceVal).toFixed(5)}</div>
                  <div className='actions'>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showWalletOpen) {
                          closeWalletModel()
                        } else {
                          showWalletModel()
                        }
                      }}>Switch Account</Button>
                  </div>
                </div>
                <div className="item">
                  <div className='token'>
                    <img className='collection' src={require('../../assets/collection.png')} alt="" />
                    <img className='token_img' src={require('../../assets/dot_logo.png')} alt="" />
                    <span>DOT-EVM</span>
                  </div>
                  <div className='balance'>{EVMBalanceLoading ? <LoadingOutlined /> : EVMBalance}DOT</div>
                  <div className='price'>${dotPriceVal}</div>
                  <div className='value'>${(EVMBalance * dotPriceVal).toFixed(5)}</div>
                  <div className='actions'>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showWalletOpen) {
                          closeWalletModel()
                        } else {
                          showWalletModel()
                        }
                      }}>Switch Account</Button>
                  </div>
                </div>
                {substrateTokens.map((element, index) => (
                  <div className="item" key={`substrateTokens_${index}`}>
                    <div className="token">
                      <img className="collection" src={require('../../assets/collection.png')} alt="" />
                      <img className="token_img" src={require('../../assets/dot_logo.png')} alt="" />
                      <span>{element.symbol}</span>
                    </div>
                    <div className="balance">{element.isLoading ? <LoadingOutlined /> : element.price}{element.BalanceWei}</div>
                    <div className="price">${dotPriceVal}</div>
                    <div className="value">${(element.price * dotPriceVal).toFixed(5)}</div>
                    <div className="actions">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (showWalletOpen) {
                            closeWalletModel();
                          } else {
                            showWalletModel();
                          }
                        }}
                      >
                        Switch Account
                      </Button>
                    </div>
                  </div>
                ))}
                {evmTokens.map((element, index) => (
                  <div className="item" key={`evmTokens_${index}`}>
                    <div className="token">
                      <img className="collection" src={require('../../assets/collection.png')} alt="" />
                      <img className="token_img" src={require('../../assets/dot_logo.png')} alt="" />
                      <span>{element.symbol}</span>
                    </div>
                    <div className="balance">{element.isLoading ? <LoadingOutlined /> : element.price}{element.BalanceWei}</div>
                    <div className="price"></div>
                    <div className="value"></div>
                    <div className="actions">
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (showWalletOpen) {
                            closeWalletModel();
                          } else {
                            showWalletModel();
                          }
                        }}
                      >
                        Switch Account
                      </Button>
                    </div>
                  </div>
                ))}
                {/* {
                  Array.from({ length: 100 }).map(number => {

                    return <div className="item">
                      <div className='token'>
                        <img className='collection' src={require('../../assets/collection.png')} alt="" />
                        <img className='token_img' src={require('../../assets/dot_logo.png')} alt="" />
                        <span>DOT</span>
                      </div>
                      <div className='balance'>0*cDOT</div>
                      <div className='price'>$4.11</div>
                      <div className='value'>$0</div>
                      <div className='actions'>...</div>
                    </div>
                  })
                } */}

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wallet_model" >
        <div className="ConnectWallet_dropdown">
          <div className="">
            <div className="mantine-Paper-root">
              <h4 className=" mantine-Title-root">Networks</h4>
              <div className="icon">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fill="none" strokeMiterlimit="10" strokeWidth="32" d="M248 64C146.39 64 64 146.39 64 248s82.39 184 184 184 184-82.39 184-184S349.61 64 248 64z"></path>
                  <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M220 220h32v116"></path>
                  <path fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M208 340h88"></path>
                  <path d="M248 130a26 26 0 1026 26 26 26 0 00-26-26z"></path>
                </svg>
              </div>
              <div className=" mantine-Group-root">
                <div className="MoonChainButton_root__m3P0N">
                  <button className="mantine-focus-auto MoonChainSection_root__JetMm MoonChainButton_button__181Bc MoonChainButton_selected__mFVtD  mantine-UnstyledButton-root" type="button">
                    <div className=" mantine-Group-root">
                      {/* <img alt="Moonbeam" src={require('../../assets/moonbeam-logo.webp')} width="116" height="24" decoding="async" data-nimg="future" /> */}
                      <p>Magnet</p>
                      <p className="mantine-focus-auto m-b6d8b162 mantine-Text-root" data-size="xs" >Selected</p>
                    </div></button>

                </div>
                {/* <div className="MoonChainButton_root__m3P0N">
                  <button className="mantine-focus-auto MoonChainSection_root__JetMm MoonChainButton_button__181Bc  mantine-UnstyledButton-root" type="button">
                    <div className="m-4081bf90 mantine-Group-root" >
                      <img alt="Moonbeam" src={require('../../assets/moonriver-logo.webp')} width="116" height="24" decoding="async" data-nimg="future" />
                    </div></button>

                </div>
                <div className="MoonChainButton_root__m3P0N">
                  <button className="mantine-focus-auto MoonChainSection_root__JetMm MoonChainButton_button__181Bc m-87cf2631 mantine-UnstyledButton-root" type="button">
                    <div className="m-4081bf90 mantine-Group-root" >
                      <img alt="Moonbeam" src={require('../../assets/moonbase-logo.webp')} width="116" height="24" decoding="async" data-nimg="future" />
                    </div></button>

                </div> */}
              </div>
            </div>
            <Tabs centered defaultActiveKey="1" activeKey={selectTabsOrd} tabBarGutter={16} type="card" onTabClick={(key) => {
              setSelectTabsOrd(key)
            }}>
              <TabPane tab="EVM wallet" key="1">
                <div className="tabcontent">
                  <div className=" mantine-Group-root">
                    <div className="MoonChainButton_root__m3P0N">
                      <button
                        className={`mantine-focus-auto mantine-active theme_button__Gl8qQ WalletButton_button__8SwN_ m-77c9d27d mantine-Button-root m-87cf2631 mantine-UnstyledButton-root ${activeEVMButton === 1 ? 'WalletButton_selected__AKZ36' : ''}`}
                        onClick={() => handleEVMButtonClick(1)}
                        type="button" data-testid="wallet-MetaMask-button">
                        <span className="WalletButton_inner__CItsG m-80f1301b mantine-Button-inner">
                          <span className="WalletButton_rightIcon__8DZrY m-a74036a mantine-Button-section"
                            data-position="left">
                            <div className="" >
                              <img alt="MetaMask"
                                src={require('../../assets/metamask.svg').default} width="24" height="24"
                                decoding="async" data-nimg="future" loading="lazy" />
                            </div>
                          </span>
                          <span className="WalletButton_label__ipoVW m-811560b9 mantine-Button-label">
                            MetaMask
                          </span>
                        </span>
                      </button>

                    </div>
                    {/* <div className="MoonChainButton_root__m3P0N">
                      <button
                        className={`mantine-focus-auto mantine-active theme_button__Gl8qQ WalletButton_button__8SwN_ m-77c9d27d mantine-Button-root m-87cf2631 mantine-UnstyledButton-root ${activeEVMButton === 2 ? 'WalletButton_selected__AKZ36' : ''}`}
                        onClick={() => handleEVMButtonClick(2)}
                        type="button" data-testid="wallet-Talisman-button">
                        <span className="WalletButton_inner__CItsG m-80f1301b mantine-Button-inner">
                          <span className="WalletButton_rightIcon__8DZrY m-a74036a mantine-Button-section"
                            data-position="left">
                            <div className="" >
                              <img alt="talisman"
                                src={require('../../assets/talisman.svg').default} width="24" height="24"
                                decoding="async" data-nimg="future" loading="lazy" />
                            </div>
                          </span>
                          <span className="WalletButton_label__ipoVW m-811560b9 mantine-Button-label">
                            Talisman
                          </span>
                        </span>
                      </button>

                    </div> */}

                  </div>
                  <div className="account_box" >
                    <label className="mantine-focus-auto m-b6d8b162 mantine-Text-root" data-size="sm"
                    >
                      Account
                    </label>
                    <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                    >
                      <div className="m-4081bf90 mantine-Group-root">
                        <div className="" >
                          <div className="paper" >
                            <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                fill="#fc3900">
                              </rect>
                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                fill="#f16402">
                              </rect>
                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                fill="#035d55">
                              </rect>
                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                fill="#f38800">
                              </rect>
                            </svg>
                          </div>
                        </div>
                        <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                          {!!accountAddress ? `${accountAddress.slice(0, 10)}...${accountAddress.slice(-8)}` : "Not connected"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Substrate wallet" key="2">
                <div className="tabcontent">
                  <div className=" mantine-Group-root">
                    <div className="MoonChainButton_root__m3P0N">
                      <button
                        className={`mantine-focus-auto mantine-active theme_button__Gl8qQ WalletButton_button__8SwN_ m-77c9d27d mantine-Button-root m-87cf2631 mantine-UnstyledButton-root ${activeSubButton === 1 ? 'WalletButton_selected__AKZ36' : ''}`}
                        onClick={() => handleSUBButtonClick(1)}
                        data-variant="light" data-with-left-section="true" type="button" data-testid="wallet-Talisman-button">
                        <span className="WalletButton_inner__CItsG m-80f1301b mantine-Button-inner">
                          <span className="WalletButton_rightIcon__8DZrY m-a74036a mantine-Button-section"
                            data-position="left">
                            <div className="" >
                              <img alt="talisman"
                                src={require('../../assets/polkadot.svg').default} width="24" height="24"
                                decoding="async" data-nimg="future" loading="lazy" />
                            </div>
                          </span>
                          <span className="WalletButton_label__ipoVW m-811560b9 mantine-Button-label">
                            Polkadot
                          </span>
                        </span>
                      </button>

                    </div>

                  </div>
                  <div className="account_box" >
                    <label className="mantine-focus-auto m-b6d8b162 mantine-Text-root" data-size="sm"
                    >
                      Account
                    </label>

                    {
                      SUBAccounts.length === 0
                        ? <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                        > <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                            Not connected
                          </p></div>
                        : SUBAccounts.length === 1
                          ? <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                          ><div className="m-4081bf90 mantine-Group-root">
                              <div className="" >
                                <div className="paper" >
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                    <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                      fill="#fc3900">
                                    </rect>
                                    <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                      fill="#f16402">
                                    </rect>
                                    <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                      fill="#035d55">
                                    </rect>
                                    <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                      fill="#f38800">
                                    </rect>
                                  </svg>
                                </div>
                              </div>
                              <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                {SUBAccounts[0].meta.name}
                              </p>
                            </div>
                          </div>
                          : <Popover content={PopoverContent}
                            // open={popoverOpen}
                            onOpenChange={handlepopOpenChange}
                            className="subWalletPopover" trigger="click" >
                            <Button style={{
                              display: 'block',
                              width: '100%'
                            }}>

                              <div className="m-4081bf90 mantine-Group-root" style={{
                                justifyContent: 'flex-start'
                              }}>
                                <div className="" >
                                  <div className="paper" >
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                      <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                        fill="#fc3900">
                                      </rect>
                                      <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                        fill="#f16402">
                                      </rect>
                                      <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                        fill="#035d55">
                                      </rect>
                                      <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                        fill="#f38800">
                                      </rect>
                                    </svg>
                                  </div>
                                </div>
                                <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                  { }
                                  {selectSubWallet !== undefined ? selectSubWallet.meta.name : SUBAccounts[0].meta.name}
                                </p>
                              </div>
                            </Button>
                          </Popover>
                    }
                  </div>
                </div>
              </TabPane>
            </Tabs>


          </div>
        </div>
      </div>


      <div
        className={`overlay ${isShowImport ? 'active' : ''}`}
        onClick={(e) => {
          setIsShowImport(false);
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
      >
        <div
          className="import-container"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }}
        >
          <div className="import-container-title">import token</div>
          <div className="import-container-selecter">
            <div
              className={`import-container-selecter-subscript ${importType === 'Substrate' ? 'subscript-left' : 'subscript-left'
                }`}
            ></div>
            <div className="import-container-selecter-wrap">
              <div
                style={{
                  display: 'none'
                }}
                className={`import-container-selecter-item ${importType === 'Substrate' ? 'active' : ''}`}
                onClick={() => {
                  setImportType('Substrate');
                }}
              >
                Substrate
              </div>
              <div
                className={`import-container-selecter-item ${importType === 'Evm' ? 'active' : ''}`}
                onClick={() => {
                  setImportType('Evm');
                }}
              >
                Evm
              </div>
            </div>
          </div>
          <div className="import-container-input-title">Token contract address</div>
          <div className="import-container-input-wrap">
            <input type="text"
              // onBlur={(e) => {
              //   queryTokenInfoAndBalance(e.target.value)
              // }}
              className="import-container-input-item" ref={importAddressRef} />
          </div>
          <div className="import-container-input-title">Token symbol</div>
          <div className="import-container-input-wrap">
            <input type="text"
              onChange={(e) => {
                setTokenSymbolValue(e.target.value)
              }}
              value={TokenSymbolValue} className="import-container-input-item" ref={importSymbolRef} />
          </div>
          <div className="import-container-btn" onClick={onImport}>
            import
          </div>
        </div>
      </div>
    </>
  );
}

export default ListView;