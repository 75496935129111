import './index.scss'
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from 'react';
gsap.registerPlugin(ScrollTrigger);


const ListTewst = (): JSX.Element => {

  useEffect(() => {

    const details = gsap.utils.toArray(".desktopContentSection:not(:first-child)")
    const photos = gsap.utils.toArray(".desktopPhoto:not(:first-child)")


    gsap.set(photos, { yPercent: 101 })

    const allPhotos = gsap.utils.toArray(".desktopPhoto")


    // create
    let mm = gsap.matchMedia();

    // add a media query. When it matches, the associated function will run
    mm.add("(min-width: 600px)", () => {

      // this setup code only runs when viewport is at least 600px wide
      console.log("desktop")

      ScrollTrigger.create({
        trigger: ".project",
        start: "top top",
        end: "bottom bottom",
        pin: ".right",
        // markers: true
      })



      ScrollTrigger.create({
        trigger: ".projecttitle",
        start: "top 80%", // 修改起始位置为 60%
        end: "top+=1000 20%", // 修改结束位置为 40
        // markers: true
        scrub: true,
        markers: true,
      })

      details.forEach((detail, index) => {
        //@ts-ignore
        let headline = detail.querySelector("h1");
        let animation = gsap.timeline()
          //@ts-ignore
          .to(photos[index], { yPercent: 0 })
          //@ts-ignore
          .set(allPhotos[index], { autoAlpha: 0 });

        let prevScroll = window.pageYOffset || document.documentElement.scrollTop;
        let currentScroll;
        ScrollTrigger.create({
          trigger: headline,
          start: "top 60%", // 修改起始位置为 60%
          end: "top 40%", // 修改结束位置为 40%
          animation: animation,
          scrub: true,
          markers: true,
          onEnter: () => {
            //@ts-ignore
            gsap.set(allPhotos[index], { autoAlpha: 0.5 });
          },
          onUpdate: (self) => {
            // Get the current scroll position
            currentScroll = window.pageYOffset || document.documentElement.scrollTop;

            // Compare with the previous scroll position
            if (currentScroll > prevScroll) {
              // Scrolling down
              // console.log("Scrolling Down", allPhotos[index + 1], allPhotos[index]);
              //@ts-ignore
              gsap.set(allPhotos[index], { autoAlpha: 0.5 });
              //@ts-ignore
              gsap.set(allPhotos[index + 1], { autoAlpha: 1 });
            } else {
              // Scrolling up
              // console.log("Scrolling Up", allPhotos[index]);
              //@ts-ignore
              gsap.set(allPhotos[index], { autoAlpha: 1 });
              //@ts-ignore
              gsap.set(allPhotos[index + 1], { autoAlpha: .5 });
            }

            // Update the previous scroll position
            prevScroll = currentScroll;
          },

        });
      });




      return () => { // optional
        // custom cleanup code here (runs when it STOPS matching)
        console.log("mobile")
      };
    });

  }, []);
  return (
    <>
      <div className="test">
        <div className="top"></div>
        <div className={['project'].join(' ')} >


          <div className="left">
            <div className="desktopContent">
              <div className="desktopContentSection">
                <h1>Red</h1>
                <p>Red is a color often associated with strong emotions such as passion, love, and anger. It's a bold and attention-grabbing color that can evoke feelings of excitement, warmth, and energy.</p>
              </div>
              <div className="desktopContentSection">
                <h1>Green</h1>
                <p>Green is a color that is often associated with nature, growth, and harmony. It is a calming and relaxing color that can evoke feelings of balance, stability, and freshness. In color psychology, green is said to represent balance and stability, making it a popular choice for branding and marketing in the health and wellness industry. </p>
              </div>
              <div className="desktopContentSection">
                <h1>Pink</h1>
                <p>Pink is a color that is often associated with femininity, romance, and sweetness. It is a softer and more delicate shade of red that can evoke feelings of warmth, love, and nurturing.</p>
                <p>In the world of branding and marketing, pink is often used to target a female audience or to promote products that are associated with beauty, love, or romance.</p>
                <p>Pink is also used in the food industry, as it is associated with sweetness and desserts. Pink is often used in breast cancer awareness campaigns, as it has become the signature color of the movement. </p><p>Pink is also commonly used in baby showers and weddings, as it symbolizes love, innocence, and new beginnings.</p>
              </div>
              <div className="desktopContentSection">
                <h1>Blue</h1>
                <p>Blue is a color that is often associated with calmness, trust, and reliability. It is a peaceful and serene color that can evoke feelings of stability, security, and professionalism. In color psychology, blue is said to represent loyalty and trust, making it a popular choice for branding and marketing in the finance and technology industries.</p>
              </div>
              <div className="desktopContentSection">
                <h1>Blue</h1>
                <p>Blue is a color that is often associated with calmness, trust, and reliability. It is a peaceful and serene color that can evoke feelings of stability, security, and professionalism. In color psychology, blue is said to represent loyalty and trust, making it a popular choice for branding and marketing in the finance and technology industries.</p>
              </div>
            </div>
          </div>
          <div className="right">
            <h2 className='projecttitle'>Project Characteristics</h2>

            <div className="project-list desktopPhotos">
              <div className="item projectItem desktopPhoto">
                <div className="ord active">
                  01
                </div>
                <div className="content">
                  <h3>DOT as the native token</h3>
                  <p> Magnet operates as a token-less chain, with all token functionalities carried out by DOT obtained through XCM.</p>
                </div>
              </div>

              <div className="item projectItem desktopPhoto">
                <div className="ord">
                  02
                </div>
                <div className="content">
                  <h3>Flexible and fast access through PAYG</h3>
                  <p> Magnet utilizes the PAYG model to quickly and flexibly order blockspace and access the Polkadot network.</p>
                </div>
              </div>

              <div className="item projectItem desktopPhoto">
                <div className="ord">
                  03
                </div>
                <div className="content">
                  <h3>Sustainable operation through gas fee revenue</h3>
                  <p> Magnet collects gas fees on its network and uses them to purchase the necessary blocks. The difference between the actual ordering price and the obtained gas fees becomes the Magnet's revenue, which will be allocated to the Polkadot treasury, system reserves, collator incentives, and project operations.</p>
                </div>
              </div>

              <div className="item projectItem desktopPhoto">
                <div className="ord">
                  04
                </div>
                <div className="content">
                  <h3>Support for EVM and WASM</h3>
                  <p> Initially, Magnet supports EVM to rapidly acquire and accumulate users. Later, it will also support WASM to prepare for the future high-performance smart contract market.</p>
                </div>
              </div>

              <div className="item projectItem desktopPhoto">
                <div className="ord">
                  05
                </div>
                <div className="content">
                  <h3>Governance delegated to DOT holders</h3>
                  <p> Magnet's on-chain governance is entrusted to DOT holders, making DOT as the governance token of Magnet.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="bottom"></div>
      </div>
    </>
  );
}

export default ListTewst;