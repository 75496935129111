import { useEffect, useRef, useState } from 'react';
import { ApiPromise, WsProvider } from '@polkadot/api';
import { LoadingOutlined } from '@ant-design/icons';
import Web3 from 'web3';
import { Button, Col, Form, InputNumber, Popover, Row, Select, Spin, Steps, Tabs } from 'antd';
import './index.scss'
import TabPane from 'antd/es/tabs/TabPane';
import { web3Accounts, web3Enable, web3FromSource } from "@polkadot/extension-dapp";
import gsap from 'gsap';
import _ from 'lodash';
import { decodeAddress } from '@polkadot/util-crypto';
import { u8aToHex } from '@polkadot/util';
import { toast } from 'react-toastify';
import getDotPrice from '../../api';
import { ImportTokenModel } from '../../models/import_token';
import { StorageUtil, evmKeyString } from '../../libs/local_storage';
import modal from 'antd/es/modal';

const Bridges = (): JSX.Element => {
  const [dotPriceVal, setDotPriceVal] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dotPrice = await getDotPrice();
        setDotPriceVal(dotPrice)
        // 在这里使用 dotPrice 进行其他操作
      } catch (error: any) {
        // 错误处理
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };

    fetchData();
  }, []);
  // 连接波卡区块
  const connectToApi8854 = async () => {
    // const provider = new WsProvider('wss://magnet-rpc.magport.io/ws');
    const provider = new WsProvider('wss://magnet-rpc.magport.io/ws');
    return await ApiPromise.create({ provider });
  };
  const connectToApi9943 = async () => {
    // const provider = new WsProvider('wss://app.magport.io/');
    const provider = new WsProvider('wss://rococo-rpc.polkadot.io');
    return await ApiPromise.create({ provider });
  };


  // 断开metamask钱包
  const [disconnect, setDisconnect] = useState(false)
  // 钱包余额
  const [balance, setBalance] = useState<any>(null);
  // metamask钱包地址
  const [accountAddress, setAccountAddress] = useState("");


  const fetchBalance = async () => {
    // 使用 MetaMask 提供的 provider
    if (!(window as any).ethereum) {
      console.error('MetaMask extension not detected');
      return;
    }
    //@ts-ignore
    const web3 = new Web3(window.ethereum);

    // 获取当前账户地址
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];

    // 获取账户余额（以太币）
    const weiBalance = await web3.eth.getBalance(address);

    // 将余额转换为以太币
    const etherBalance = web3.utils.fromWei(weiBalance, 'ether');
    console.log('%c🀅 etherBalance', 'color: #eeff00; font-size: 20px;', etherBalance);

    // 更新组件中的余额状态
    setBalance((Math.floor(Number(etherBalance) * 1000) / 1000).toFixed(3));
  };
  // 监听metamask余额
  useEffect(() => {

    if (!(window as any).ethereum) {
      console.error('MetaMask extension not detected');
      return;
    }
    // 初始化获取余额
    fetchBalance();

    // 监听 MetaMask 账户变化，更新余额
    // @ts-ignore
    window.ethereum.on('accountsChanged', fetchBalance);

    // 清理函数
    return () => {
      // 移除监听
      //@ts-ignore
      window.ethereum.off('accountsChanged', fetchBalance);
    };
  }, []);


  useEffect(() => {
    // 监听钱包地址变化
    const checkAccountChange = async () => {
      if (!(window as any).ethereum) {
        console.error('MetaMask extension not detected');
        return;
      }
      // 使用 MetaMask 提供的 provider
      //@ts-ignore
      const web3 = new Web3(window.ethereum);

      // 获取当前账户地址
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];

      // 检查地址是否发生变化
      if (address !== accountAddress) {
        // 处理地址变化的逻辑
        console.log('Address changed:', address);
        setAccountAddress(address);
      }

      try {
        // 请求用户授权连接到 MetaMask
        //@ts-ignore
        await window.ethereum.enable();

        // 连接到指定网络（例如 Rinkeby）
        switchToCustomNetwork();

      } catch (error) {
        console.error(error);
      }
      setDisconnect(true)
    };
    // 初始化监听
    checkAccountChange();

    // 监听 MetaMask 账户变化
    //@ts-ignore
    window.ethereum.on('accountsChanged', checkAccountChange);

    // 清理函数
    return () => {
      // 移除监听
      //@ts-ignore
      window.ethereum.off('accountsChanged', checkAccountChange);
    };
  }, [accountAddress]);

  useEffect(() => {
    // 连接到指定网络（例如 Rinkeby）
    switchToCustomNetwork();
  }, [])

  const switchToCustomNetwork = async () => {
    if (!(window as any).ethereum) {
      console.error('MetaMask extension not detected');
      return;
    }

    const ethereum = (window as any).ethereum;
    const chainId = process.env.REACT_APP_CHAINID;
    console.log('%c🀆 chainId', 'color: #86bf60; font-size: 20px;', chainId);

    try {
      // 请求用户授权连接 MetaMask
      await ethereum.request({ method: 'eth_requestAccounts' });

      // 切换网络
      await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: chainId, // 这是网络 ID，例如 '0x1' 代表主网
          chainName: 'Magnet Network', // 自定义网络名称
          nativeCurrency: {
            name: 'dot',
            symbol: 'dot',
            decimals: 18,
          },
          rpcUrls: ['https://magnet-rpc.magport.io/ws'], // 自定义网络的 RPC URL
        }],
      });
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }],
      });
    } catch (error) {
      console.error('Error switching network:', error);
    }
  };


  // 获取meatmask钱包余额
  async function getAccount() {
    if (!(window as any).ethereum) {
      console.error('MetaMask extension not detected');
      return;
    }
    // @ts-ignore
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];

    return account;
  }

  // 连接evm钱包，并保存对应钱包类型
  const handleSUBButtonClick = (buttonId: any) => {
    setActiveSubButton(buttonId);
    connectWallet()
    closeWalletModel()
  };

  const [SUBAccounts, setSUBAccounts] = useState<any[]>([])
  const [balanceCount, setBalanceCount] = useState(0)
  const connectButtonOnClick = () => {
    console.log(window);
    if (
      //@ts-ignore
      typeof window !== "undefined" && typeof window.ethereum !== "undefined"
    ) {
      getAccount().then((response: any) => {
        setAccountAddress(response);
        setDisconnect(true)
      });
    } else {
      console.log("error");
    }
  };
  //连接substrate wallet
  const connectWallet = async () => {


    try {
      const extensions = await web3Enable("Data tranding market");
      if (extensions.length === 0) { // 未安装波卡钱包插件
        console.log("Please create cess-hacknet chain account.");
        return;
      }
      let allAccounts = await web3Accounts(); // 获取所有波卡钱包账户

      const filteredAccounts = _.filter(allAccounts, (item) => item.type !== 'ethereum');
      setSUBAccounts(filteredAccounts)
      setSelectSunWallet(filteredAccounts[0])

      await getSubBalance(filteredAccounts[0].address)
      // disconnectFromApi(api);


      if (allAccounts.length === 0) { //未创建钱包账户
        console.log("Please create account fisrt.");
        return;
      }
    } catch (error) {
      console.error('Error sending transaction:', error);
    }


    // (await api).disconnect();
  }
  // tab序号
  const [selectTabsOrd, setSelectTabsOrd] = useState('1')


  const [formData, setFormData] = useState({
    token: 'dot',
    from: {
      type: "Magnet",
      address: ''
    },
    to: {
      type: "Relaychain",
      address: ''
    },
    count: 0
  })


  const [gasfreeValue, setGasfreeValue] = useState(0)


  const AddressToHex = (address: any) => {
    // 将 SS58 地址解码为字节
    const addressBytes = decodeAddress(address);

    // 将字节转换为十六进制字符串
    const hexAddress = u8aToHex(addressBytes);
    return hexAddress
  }

  const send_dot_RtoP = async () => {
    let api;
    let fungibleValue = BigInt(Math.floor(formData.count * 10 ** 12));
    const Parachain = process.env.REACT_APP_PARACHAIN
    try {

      const destRTP = { V3: { parents: 0, interior: { X1: { Parachain: Parachain } } } };
      const beneficiaryRTP = {
        V3: {
          parents: 0,
          interior: {
            X1: { AccountId32: { network: null, id: AddressToHex(selectSubWallet.address) } },
          },
        },
      };
      const feeAssetItem = 0;

      api = await connectToApi9943();
      const assetsRTP = {
        V3: [
          {
            id: { Concrete: { parents: 0, interior: 'Here' } },
            fun: { Fungible: fungibleValue.toString() },
          },
        ],
      };

      const tx = api.tx.xcmPallet.reserveTransferAssets(destRTP, beneficiaryRTP, assetsRTP, feeAssetItem);
      const { partialFee } = await tx.paymentInfo(selectSubWallet.address);
      const partialFeeInWei = BigInt(partialFee.toString()); // 将 partialFee 转换为 Wei
      const partialFeeInPlanck = partialFeeInWei / BigInt(1e10); // 将 Wei 转换为 Planck
      const partialFeeInDOT = Number(partialFeeInPlanck) / 1e10; // 将 Planck 转换为 DOT
      setGasfreeValue(Number(partialFeeInDOT))

      // 设置最大 gas 费用
      const maxGasAllowed = 500000; // 设置最大允许的 gas 费用
      const maxGasFee = partialFee.toNumber() > maxGasAllowed ? maxGasAllowed : partialFee.toNumber();
      const injector = await web3FromSource(selectSubWallet.meta.source);


      // 创建自定义选项对象
      const customOptions = {
        signer: injector.signer,
        maxFee: maxGasFee,
      };
      tx.signAndSend(selectSubWallet.address, customOptions, ({ status }) => {

        if (status.isInBlock) {
          setCurrent(2)
          toggleTimer()
          toast.success('success', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          if (status.type === 'Ready') {
            setCurrent(1)
            // 计时器启动
            toggleTimer()
          }
        }
      }).catch((error: any) => {
        handleError(error)
        setCurrent(0)
        setSeconds(0)
      });

    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error: any) => {
    setCurrent(0);
    toast.error(error.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };



  // 获取钱包余额
  const getSubBalance = async (address?: string, type: string | undefined = undefined) => {
    setBalanceLoading(true);

    // 检查是否提供了 address 参数，如果未提供，则使用 selectSubWallet.address 作为默认值
    const targetAddress = address || selectSubWallet.address;

    // 如果没有传入 type 参数，则使用 formData 中的 from.type
    const targetType = type || formData.from.type;

    if (electConvertOrdRef.current === '2') {
      if (targetType === 'Relaychain') {
        await getSubstrateBalance(targetAddress);
      } else {
        fetchBalance()
      }
    } else {
      if (targetType === 'Magnet') {
        await getSubstrateBalance(targetAddress);
      } else {
        await getMagnetBalance(targetAddress);
      }
    }



    setBalanceLoading(false);
  };

  const getSubstrateBalance = async (address: string) => {
    // if (electConvertOrdRef.current === '2') {
    //   fetchBalance()
    // } else {

    if (!address) {
      return
    }
    const api = await connectToApi8854();
    //@ts-ignore
    const { data: balance } = await api.query.system.account(address);


    const balanceInPlanck = Number(balance.free.toString()); // 转换为 BigInt 类型
    const DOTtoPlanck = 1000000000000000000; // 根据 type 设置 Planck
    const balanceInDOT = (balanceInPlanck / DOTtoPlanck).toString(); // 将 BigInt 转换为字符串
    //@ts-ignore
    // setBalanceCount(Number(balanceInDOT).toFixed(3));
    setBalanceCount(Math.floor(balanceInDOT * 1000) / 1000)

    // }
  }

  const getMagnetBalance = async (address: string) => {


    if (!address) {
      return
    }
    const api = await connectToApi9943();
    //@ts-ignore
    const { data: balance } = await api.query.system.account(address);
    const DOTtoPlanck = 1000000000000; // 1 DOT = 1,000,000,000 Planck
    const balanceInPlanck = balance.free.toNumber(); // 假设 balance 是一个 BN（BigNumber）对象
    const balanceInDOT = (balanceInPlanck / DOTtoPlanck).toFixed(9);
    //@ts-ignore
    setBalanceCount(Math.floor(balanceInDOT * 1000) / 1000)

  }




  // 使用Polkadot.js API发送XCM消息
  const send_dot_PtoR = async () => {

    let fungibleValue = Math.floor(formData.count * 10 ** 12);
    const dest = {
      V3: {
        parents: 1,
        interior: 'Here',
      },
    };

    const beneficiary = {
      V3: {
        parents: 0,
        interior: {
          X1: {
            AccountId32: {
              network: null,
              id: AddressToHex(selectSubWallet.address)
            },
          },
        },
      },
    };

    const assets = {
      V3: [
        {
          id: {
            Concrete: {
              parents: 1,
              interior: 'Here',
            },
          },
          fun: { Fungible: fungibleValue.toString() },
        },
      ],
    };

    const feeAssetItem = 0;

    const weightLimit = {
      Limited: {
        refTime: 400000000,
        proofSize: 14484,
      },
    };
    let api;
    try {
      api = await connectToApi8854()

      const tx = api.tx.polkadotXcm.limitedReserveTransferAssets
        (
          dest,
          beneficiary,
          assets,
          feeAssetItem,
          weightLimit
        );


      const { partialFee } = await tx.paymentInfo(selectSubWallet.address);


      const partialFeeInWei = BigInt(partialFee.toString()); // 将 partialFee 转换为 Wei
      const partialFeeInPlanck = partialFeeInWei / BigInt(1e10); // 将 Wei 转换为 Planck
      const partialFeeInDOT = Number(partialFeeInPlanck) / 1e10; // 将 Planck 转换为 DOT
      setGasfreeValue(Number(partialFeeInDOT))


      // 设置最大 gas 费用
      const maxGasAllowed = 500000; // 设置最大允许的 gas 费用
      const maxGasFee = partialFee.toNumber() > maxGasAllowed ? maxGasAllowed : partialFee.toNumber();
      const injector = await web3FromSource(selectSubWallet.meta.source);


      // 创建自定义选项对象
      const customOptions = {
        signer: injector.signer,
        maxFee: maxGasFee,
      };

      // // 5. Send the transaction
      tx.signAndSend(selectSubWallet.address, customOptions, ({ status }) => {
        if (status.isInBlock) {
          setCurrent(2)
          toggleTimer()
          toast.success('success', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          if (status.type === 'Ready') {
            setCurrent(1)
            // 计时器启动
            toggleTimer()
          }
        }
      }).catch((error: any) => {
        handleError(error)
      });

    } catch (error) {
      handleError(error);
    }
  };


  const [sendLoading, setSendLoading] = useState(false)
  const [selectSubWallet, setSelectSunWallet] = useState<any>()


  const MangetEvmFun = () => {
    if (formData.from.type === 'Magnet') {
      transferToSubstrate(selectSubWallet.address, formData.count.toString())
    } else {
      MagnetToevm()
    }
  }


  const MagnetToevm = async () => {
    // Substrate节点的WebSocket连接
    const provider = new WsProvider('wss://magnet-rpc.magport.io/ws');



    const api = await ApiPromise.create({ provider });

    const fungibleValue = Math.floor(formData.count * 10 ** 18);
    const amount = fungibleValue.toString();

    // 构造extrinsic，使用字符串传递大数值
    const extrinsic = api.tx.evmUtils.transferToEvm(accountAddress, amount);



    const { partialFee } = await extrinsic.paymentInfo(selectSubWallet.address);
    const partialFeeInWei = BigInt(partialFee.toString()); // 将 partialFee 转换为 Wei
    const partialFeeInPlanck = partialFeeInWei / BigInt(1e10); // 将 Wei 转换为 Planck
    const partialFeeInDOT = Number(partialFeeInPlanck) / 1e10; // 将 Planck 转换为 DOT
    setGasfreeValue(Number(partialFeeInDOT))
    const injector = await web3FromSource(selectSubWallet.meta.source);
    // 提交extrinsic
    // // 5. Send the transaction
    extrinsic.signAndSend(selectSubWallet.address, { signer: injector.signer }, ({ status }) => {
      if (status.isInBlock) {
        setCurrent(2)
        toggleTimer()
        toast.success('success', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log(`Completed at block hash #${status.asInBlock.toString()}`);
        console.log(`Completed at block hash #${status.asInBlock.toString()}`);
      } else {
        if (status.type === 'Ready') {
          setCurrent(1)
          // 计时器启动
          toggleTimer()
        }
        console.log(`Current status: ${status.type}`);
      }
    }).catch((error: any) => {
      handleError(error)
    });

  }
  const transferToSubstrate = async (ss58Address: any, amountInEther: string) => {
    try {
      const web3 = new Web3(window.ethereum);


      // 检查钱包连接状态
      //@ts-ignore
      if (!window.ethereum.isConnected()) {
        throw new Error('Wallet not connected.');
      }
      // 定义合约 ABI
      const contractABI = [
        {
          "constant": false,
          "inputs": [{ "name": "ss58addr", "type": "string" }],
          "name": "transferToSubstrate",
          "outputs": [],
          "payable": true,
          "stateMutability": "payable",
          "type": "function"
        }
      ];

      const contractAddress = '0x0000000000000000000000000000000000000800';
      const contract = new web3.eth.Contract(contractABI, contractAddress);
      const valueInWei = web3.utils.toWei(amountInEther, 'ether');

      // 获取 gasPrice 和 gasLimit
      const [gasPrice, estimatedGas] = await Promise.all([
        web3.eth.getGasPrice(),
        web3.eth.estimateGas({
          from: accountAddress,
          to: contractAddress,
          //@ts-ignore
          data: contract.methods.transferToSubstrate(ss58Address).encodeABI(),
          value: valueInWei
        })
      ]);


      // 设置 gasLimit，为了确保交易成功执行，在估算的 gas 基础上增加一些余量
      const gasLimit = (estimatedGas + BigInt(100000)).toString();


      const gasLimitInPlanck = BigInt(gasLimit);
      // 将 gasLimit 转换为 DOT
      const gasLimitInDOT = Number(gasLimitInPlanck) / 1e10;
      console.log(gasLimitInDOT);
      setGasfreeValue(Number(gasLimitInDOT))
      // 构建交易对象
      const txObject = {
        from: accountAddress,
        to: contractAddress,
        //@ts-ignore
        data: contract.methods.transferToSubstrate(ss58Address).encodeABI(),
        value: valueInWei,
        gas: gasLimit,
        gasPrice: gasPrice
      };

      // 发送交易并监听事件
      //@ts-ignore
      await web3.eth.sendTransaction(txObject, accountAddress)
        .on('transactionHash', (hash: any) => {
          setCurrent(1)
          // 计时器启动
          toggleTimer()
        })
        .on('receipt', (receipt: any) => {
          setCurrent(2)
          toggleTimer()
          toast.success('success', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .on('confirmation', (confirmationNumber: any) => {

        })

    } catch (error: any) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

  };


  const swapFromTo = async () => {

    setFormData(prevData => ({
      ...prevData,
      from: {
        type: prevData.to.type,
        address: prevData.to.address
      },
      to: {
        type: prevData.from.type,
        address: prevData.from.address
      }
    }));
    // if (formData.from.type === 'Relaychain') {
    //   await getSubBalance(selectSubWallet.address, 'Magnet')
    // } else {
    //   if (electConvertOrdRef.current === '2') {
    //     await getMagnetBalance(selectSubWallet.address)
    //   } else {
    //     await getSubBalance(selectSubWallet.address, 'Relaychain')
    //   }
    // }
    if (formData.from.type === 'Relaychain') {
      await getSubBalance(selectSubWallet.address, 'Magnet')
    } else {
      await getSubBalance(selectSubWallet.address, 'Relaychain')
    }


  };

  const [BalanceLoading, setBalanceLoading] = useState(false)


  const [evmTokens, setEvmTokens] = useState<any[]>([]);

  const initImportTokens = async () => {

    const evmList: ImportTokenModel[] = StorageUtil.getItem(evmKeyString);
    if (evmList) {
      const list: any[] = [];
      evmList.forEach((element) => {
        list.push({
          symbol: element.symbol,
          address: element.address,
          isLoading: true,
          price: 0,
        });
      });
      setEvmTokens(list);
    }
  };
  useEffect(() => {
    initImportTokens();
  }, []);


  const openNotification = () => {

    modal.confirm({
      title: 'Notification',
      content: 'Comming Soon！',
      cancelText: 'close',
      mask: true,
      centered: true,
      footer: null,
      maskClosable: true
    });


  };


  //step内容
  const CustomStepContent1 = () => {
    const [form] = Form.useForm();

    const onFinish = async () => {


      if (formData.count === 0) {
        return
      }

      setSendLoading(true)

      if (selectConvertOrd === '1') {
        if (formData.from.type === 'Relaychain') {
          await send_dot_RtoP()
        } else {
          await send_dot_PtoR()
        }
      } else {
        // await sendTeleport()
        await MangetEvmFun()
      }
      setSendLoading(false)


    };

    const showErrorNotification = (message: any) => {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    };
    // Function to update form data
    const updateFormData = (updatedValues: any) => {
      setFormData({
        ...formData,
        ...updatedValues
      });
    };

    return (
      <div className='step1'>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ size: 'large' }}
        >

          <Row gutter={16}>
            <Col span={11}>

              <Form.Item
                name="token"
                label="Token"
              >
                <Select
                  size="large"
                  className='myselect'
                  defaultValue={'dot'}
                  onSelect={(value) => {
                    setFormData({
                      ...formData,
                      token: value
                    });
                    if (value !== 'dot') {
                      openNotification()
                    }

                  }}
                >
                  <Select.Option value="dot"><img src={require('../../assets/dot_logo.png')} alt="dot" height='34' style={{
                    marginRight: '10px'
                  }} /> DOT</Select.Option>

                  {selectConvertOrd === '2'
                    ? evmTokens.map((element, index) => {
                      return (
                        <Select.Option value={element.address} key={`token-${index}`}>
                          <img
                            src={require('../../assets/dot_logo.png')}
                            alt="dot"
                            height="34"
                            style={{
                              marginRight: '10px',
                            }}
                          />{' '}
                          {element.symbol}
                        </Select.Option>
                      );
                    })
                    : ''}
                  {/* <Select.Option value="Magnet"><img src={require('../../assets/dot_logo.png')} alt="dot" height='34' style={{
                      marginRight: '10px'
                    }} /> Magnet</Select.Option> */}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} justify="center" align="middle">
            <Col span={11}>
              <div className="item">
                <Form.Item
                  name="from"
                  label="From"
                >
                  <Select
                    size="large"
                    className='myselect'
                    defaultValue={formData.from.type}
                    value={formData.from.type}
                    onChange={async (value) => {

                      setFormData({
                        ...formData,
                        from: {
                          type: value,
                          address: selectConvertOrd === '1' ? selectSubWallet?.address : value === 'Magnet' ? accountAddress : selectSubWallet?.address || ''
                        },
                        to: {
                          type: value === 'Relaychain' ? 'Magnet' : 'Relaychain',
                          address: selectConvertOrd === '1' ? selectSubWallet?.address : value === 'Magnet' ? selectSubWallet?.address || '' : accountAddress
                        },
                        count: 0
                      });

                      if (!!selectSubWallet) {
                        await getSubBalance(selectSubWallet.address, value)
                      }
                    }}
                  >
                    <Select.Option value="Magnet">
                      {/* <img src={require('../../assets/dot_logo.png')} alt="dot" height='34' style={{
                        marginRight: '10px'
                      }} /> */}
                      {selectConvertOrd === '2' ? 'DOT-EVM' : 'Magnet'}
                    </Select.Option>
                    <Select.Option value="Relaychain">
                      {/* <img src={require('../../assets/dot_logo.png')} alt="dot" height='34' style={{
                        marginRight: '10px'
                      }} /> */}
                      {/* Relaychain */}
                      {selectConvertOrd === '2' ? 'DOT-Magnet' : 'Relaychain'}
                    </Select.Option>

                  </Select>
                </Form.Item>
                <Form.Item
                  name="sender"
                  label="Sender"
                >
                  {/* <Input size="large" placeholder="large size" /> */}
                  {
                    selectConvertOrd === '1' ?
                      <div className="select_address">
                        {
                          SUBAccounts.length === 0
                            ? <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                            > <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                Not connected
                              </p></div>
                            : SUBAccounts.length === 1
                              ? <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                              ><div className="m-4081bf90 mantine-Group-root">
                                  <div className="" >
                                    <div className="paper" >
                                      <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                        <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                          fill="#fc3900">
                                        </rect>
                                        <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                          fill="#f16402">
                                        </rect>
                                        <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                          fill="#035d55">
                                        </rect>
                                        <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                          fill="#f38800">
                                        </rect>
                                      </svg>
                                    </div>
                                  </div>
                                  <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                    {SUBAccounts[0].meta.name}
                                  </p>
                                </div>
                              </div>
                              : <Popover
                                content={PopoverContentAddress}
                                // open={popoverOpenAddress}
                                onOpenChange={handlepopAddressOpenChange}
                                className="subWalletPopover" trigger="click" >
                                <Button style={{
                                  display: 'block',
                                  width: '100%'
                                }}>

                                  <div className="m-4081bf90 mantine-Group-root" style={{
                                    justifyContent: 'flex-start'
                                  }}>
                                    <div className="" >
                                      <div className="paper" >
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                            fill="#fc3900">
                                          </rect>
                                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                            fill="#f16402">
                                          </rect>
                                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                            fill="#035d55">
                                          </rect>
                                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                            fill="#f38800">
                                          </rect>
                                        </svg>
                                      </div>
                                    </div>
                                    <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">

                                      {selectSubWallet !== undefined ? selectSubWallet.meta.name : SUBAccounts[0].meta.name}
                                    </p>
                                  </div>
                                </Button>
                              </Popover>


                        }
                        <div className="from_collectSubMWallet">
                          {
                            SUBAccounts.length > 0 ?
                              ''
                              : <>
                                <div className="wallet">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (showWalletOpen) {
                                        closeWalletModel()
                                      } else {
                                        showWalletModel()
                                      }
                                      setSelectTabsOrd('2')
                                    }}
                                  >
                                    <img src={require('../../assets/wallet.png')} alt="" />
                                    <span>Connect wallet</span>
                                  </button>
                                </div>
                              </>
                          }
                        </div>
                      </div>
                      : <>
                        {

                          formData.from.type === 'Magnet'
                            ? <div className="select_address">
                              <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                              >
                                <div className="m-4081bf90 mantine-Group-root">
                                  <div className="" >
                                    {
                                      accountAddress === '' || accountAddress === undefined
                                        ? ""
                                        : <div className="paper" >
                                          <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                              fill="#fc3900">
                                            </rect>
                                            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                              fill="#f16402">
                                            </rect>
                                            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                              fill="#035d55">
                                            </rect>
                                            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                              fill="#f38800">
                                            </rect>
                                          </svg>
                                        </div>
                                    }

                                  </div>
                                  <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                    {!!accountAddress ? accountAddress : "Not connected"}
                                  </p>
                                </div>
                              </div>

                              <div className="from_collectEVMWallet">
                                {
                                  !!accountAddress ?
                                    ''
                                    : <>
                                      <div className="wallet">
                                        <button
                                          onClick={async (e) => {
                                            e.stopPropagation();
                                            if (showWalletOpen) {
                                              closeWalletModel()
                                            } else {
                                              showWalletModel()
                                            }
                                            setSelectTabsOrd('1')
                                          }}
                                        >
                                          <img src={require('../../assets/wallet.png')} alt="" />
                                          <span>Connect wallet</span>
                                        </button>
                                      </div>
                                    </>
                                }
                              </div>
                            </div>
                            : <div className="select_address">
                              {
                                SUBAccounts.length === 0
                                  ? <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                                  > <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                      Not connected
                                    </p></div>
                                  : SUBAccounts.length === 1
                                    ? <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                                    ><div className="m-4081bf90 mantine-Group-root">
                                        <div className="" >
                                          <div className="paper" >
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                                fill="#fc3900">
                                              </rect>
                                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                                fill="#f16402">
                                              </rect>
                                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                                fill="#035d55">
                                              </rect>
                                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                                fill="#f38800">
                                              </rect>
                                            </svg>
                                          </div>
                                        </div>
                                        <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                          {SUBAccounts[0].meta.name}
                                        </p>
                                      </div>
                                    </div>
                                    : <Popover
                                      content={PopoverFromContentAddress}
                                      // open={popoverOpenAddress}
                                      onOpenChange={handlepopAddressOpenChange}
                                      className="subWalletPopover" trigger="click" >
                                      <Button style={{
                                        display: 'block',
                                        width: '100%'
                                      }}>

                                        <div className="m-4081bf90 mantine-Group-root" style={{
                                          justifyContent: 'flex-start'
                                        }}>
                                          <div className="" >
                                            <div className="paper" >
                                              <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                                <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                                  fill="#fc3900">
                                                </rect>
                                                <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                                  fill="#f16402">
                                                </rect>
                                                <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                                  fill="#035d55">
                                                </rect>
                                                <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                                  fill="#f38800">
                                                </rect>
                                              </svg>
                                            </div>
                                          </div>
                                          <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">

                                            {selectSubWallet !== undefined ? selectSubWallet.meta.name : SUBAccounts[0].meta.name}
                                          </p>
                                        </div>
                                      </Button>
                                    </Popover>


                              }
                              <div className="from_collectSubMWallet">
                                {
                                  SUBAccounts.length > 0 ?
                                    ''
                                    : <>
                                      <div className="wallet">
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (showWalletOpen) {
                                              closeWalletModel()
                                            } else {
                                              showWalletModel()
                                            }
                                            setSelectTabsOrd('2')
                                          }}
                                        >
                                          <img src={require('../../assets/wallet.png')} alt="" />
                                          <span>Connect wallet</span>
                                        </button>
                                      </div>
                                    </>
                                }
                              </div>
                            </div>
                        }
                      </>
                  }



                </Form.Item>
              </div>
            </Col>
            <Col span={2}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Button type="text"
                  style={{
                    height: 'auto',
                    lineHeight: 1
                  }}
                  onClick={async () => {
                    swapFromTo()

                  }}
                >
                  <img src={require('../../assets/exchange.png')} alt="" height={'34'} />
                </Button>
              </div>
            </Col>
            <Col span={11}>
              <div className="item">
                <Form.Item
                  name="to"
                  label="To"
                >
                  <Select
                    size="large"
                    className='myselect'
                    defaultValue={formData.to.type}
                  >
                    {/* {
                      formData.to.type === 'Magnet'
                        ? <Select.Option value="substrate">
                          <img src={require('../../assets/dot_logo.png')} alt="dot" height='34' style={{
                            marginRight: '10px'
                          }} />
                          substrate
                        </Select.Option>
                        : <Select.Option value="Magnet">
                          <img src={require('../../assets/dot_logo.png')} alt="dot" height='34' style={{
                            marginRight: '10px'
                          }} />
                          Magnet
                        </Select.Option>
                    } */}

                    <Select.Option value={formData.to.type}>
                      {selectConvertOrd === '2' ? formData.to.type === 'Magnet' ? 'DOT-EVM' : 'DOT-Magnet' : formData.to.type}
                    </Select.Option>


                  </Select>
                </Form.Item>
                <Form.Item
                  name="recipient"
                  label="Recipient"
                >
                  {
                    selectConvertOrd === '1' ?
                      <div className="select_address">
                        {
                          SUBAccounts.length === 0
                            ? <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                            > <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                Not connected
                              </p></div>
                            : SUBAccounts.length === 1
                              ? <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                              ><div className="m-4081bf90 mantine-Group-root">
                                  <div className="" >
                                    <div className="paper" >
                                      <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                        <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                          fill="#fc3900">
                                        </rect>
                                        <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                          fill="#f16402">
                                        </rect>
                                        <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                          fill="#035d55">
                                        </rect>
                                        <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                          fill="#f38800">
                                        </rect>
                                      </svg>
                                    </div>
                                  </div>
                                  <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                    {SUBAccounts[0].meta.name}
                                  </p>
                                </div>
                              </div>
                              : <Popover
                                content={PopoverContentAddress}
                                // open={popoverOpenAddress}
                                onOpenChange={handlepopAddressOpenChange}
                                className="subWalletPopover" trigger="click" >
                                <Button style={{
                                  display: 'block',
                                  width: '100%'
                                }}>

                                  <div className="m-4081bf90 mantine-Group-root" style={{
                                    justifyContent: 'flex-start'
                                  }}>
                                    <div className="" >
                                      <div className="paper" >
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                            fill="#fc3900">
                                          </rect>
                                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                            fill="#f16402">
                                          </rect>
                                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                            fill="#035d55">
                                          </rect>
                                          <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                            fill="#f38800">
                                          </rect>
                                        </svg>
                                      </div>
                                    </div>
                                    <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">

                                      {selectSubWallet !== undefined ? selectSubWallet.meta.name : SUBAccounts[0].meta.name}
                                    </p>
                                  </div>
                                </Button>
                              </Popover>


                        }
                        <div className="from_collectSubMWallet">
                          {
                            SUBAccounts.length > 0 ?
                              ''
                              : <>
                                <div className="wallet">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (showWalletOpen) {
                                        closeWalletModel()
                                      } else {
                                        showWalletModel()
                                      }
                                      setSelectTabsOrd('2')
                                    }}
                                  >
                                    <img src={require('../../assets/wallet.png')} alt="" />
                                    <span>Connect wallet</span>
                                  </button>
                                </div>
                              </>
                          }
                        </div>
                      </div>
                      : <>
                        {

                          formData.to.type === 'Magnet'
                            ? <div className="select_address">
                              <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                              >
                                <div className="m-4081bf90 mantine-Group-root">
                                  <div className="" >
                                    {
                                      accountAddress === '' || accountAddress === undefined
                                        ? ""
                                        : <div className="paper" >
                                          <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                              fill="#fc3900">
                                            </rect>
                                            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                              fill="#f16402">
                                            </rect>
                                            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                              fill="#035d55">
                                            </rect>
                                            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                              fill="#f38800">
                                            </rect>
                                          </svg>
                                        </div>
                                    }

                                  </div>
                                  <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                    {!!accountAddress ? accountAddress : "Not connected"}
                                  </p>
                                </div>
                              </div>

                              <div className="from_collectEVMWallet">
                                {
                                  !!accountAddress ?
                                    ''
                                    : <>
                                      <div className="wallet">
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (showWalletOpen) {
                                              closeWalletModel()
                                            } else {
                                              showWalletModel()
                                            }
                                            setSelectTabsOrd('1')
                                          }}
                                        >
                                          <img src={require('../../assets/wallet.png')} alt="" />
                                          <span>Connect wallet</span>
                                        </button>
                                      </div>
                                    </>
                                }
                              </div>
                            </div>
                            : <div className="select_address">
                              {
                                SUBAccounts.length === 0
                                  ? <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                                  > <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                      Not connected
                                    </p></div>
                                  : SUBAccounts.length === 1
                                    ? <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                                    ><div className="m-4081bf90 mantine-Group-root">
                                        <div className="" >
                                          <div className="paper" >
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                                fill="#fc3900">
                                              </rect>
                                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                                fill="#f16402">
                                              </rect>
                                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                                fill="#035d55">
                                              </rect>
                                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                                fill="#f38800">
                                              </rect>
                                            </svg>
                                          </div>
                                        </div>
                                        <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                          {SUBAccounts[0].meta.name}
                                        </p>
                                      </div>
                                    </div>
                                    : <Popover
                                      content={PopoverContentAddress}
                                      // open={popoverOpenAddress}
                                      onOpenChange={handlepopAddressOpenChange}
                                      className="subWalletPopover" trigger="click" >
                                      <Button style={{
                                        display: 'block',
                                        width: '100%'
                                      }}>

                                        <div className="m-4081bf90 mantine-Group-root" style={{
                                          justifyContent: 'flex-start'
                                        }}>
                                          <div className="" >
                                            <div className="paper" >
                                              <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                                <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                                  fill="#fc3900">
                                                </rect>
                                                <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                                  fill="#f16402">
                                                </rect>
                                                <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                                  fill="#035d55">
                                                </rect>
                                                <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                                  fill="#f38800">
                                                </rect>
                                              </svg>
                                            </div>
                                          </div>
                                          <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">

                                            {selectSubWallet !== undefined ? selectSubWallet.meta.name : SUBAccounts[0].meta.name}
                                          </p>
                                        </div>
                                      </Button>
                                    </Popover>


                              }
                              <div className="from_collectSubMWallet">
                                {
                                  SUBAccounts.length > 0 ?
                                    ''
                                    : <>
                                      <div className="wallet">
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (showWalletOpen) {
                                              closeWalletModel()
                                            } else {
                                              showWalletModel()
                                            }
                                            setSelectTabsOrd('2')
                                          }}
                                        >
                                          <img src={require('../../assets/wallet.png')} alt="" />
                                          <span>Connect wallet</span>
                                        </button>
                                      </div>
                                    </>
                                }
                              </div>
                            </div>
                        }
                      </>
                  }
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row gutter={16} justify="center" align="middle">

            <Col span={11} >

              <Form.Item
                name="count"
                label="Send"
              >
                <Row style={{
                  background: '#edf9f9',
                  borderRadius: '8px'
                }}
                  align='middle'
                >
                  <Col span={12} >
                    <div className="mantine-InputWrapper-root">
                      <div className="mantine-Input-wrapper">
                        {
                          selectConvertOrd === '1' ?
                            <InputNumber
                              type='number'
                              size="large"
                              controls={false}
                              className='m-8fb7ebe7'
                              disabled={balanceCount <= 0}
                              placeholder="0.0"
                              value={formData.count}
                              onBlur={(event) => {
                                const value = Number(event.target.value);

                                if (value > balanceCount) {
                                  showErrorNotification('Please enter the correct quantity!');
                                } else {
                                  updateFormData({ count: value });
                                }
                              }}
                            />
                            :

                            formData.from.type === 'Magnet' ?

                              <InputNumber
                                type='number'
                                size="large"
                                controls={false}
                                className='m-8fb7ebe7'
                                disabled={balance <= 0 ? true : false}
                                placeholder="0.0"
                                value={formData.count}
                                onBlur={(event) => {
                                  //@ts-ignore
                                  const value = Number(event.target.value);
                                  if (value > Number(balance)) {
                                    showErrorNotification('Please enter the correct quantity!2');
                                  } else {
                                    updateFormData({ count: value });
                                  }
                                }}
                              />


                              :
                              <InputNumber
                                type='number'
                                size="large"
                                controls={false}
                                className='m-8fb7ebe7'
                                disabled={balanceCount <= 0}
                                placeholder="0.0"
                                value={formData.count}
                                onBlur={(event) => {
                                  const value = Number(event.target.value);

                                  if (value > balanceCount) {
                                    showErrorNotification('Please enter the correct quantity!3');
                                  } else {
                                    updateFormData({ count: value });
                                  }
                                }}
                              />
                        }

                      </div>

                    </div>
                  </Col>
                  <Col span={12} >
                    <div className="mantine-Group-root m-4081bf90">
                      <div>
                        <div className="mantine-Stack-root">
                          <div className="m-4081bf90 mantine-Group-root">
                            <button className='m-77c9d27d'>
                              <div className="m-80f1301b">
                                <div className="m-811560b9">
                                  Min
                                </div>
                              </div>
                            </button>
                            <p className="mantine-focus-auto m-b6d8b162 mantine-Text-root" >-</p>
                          </div>
                          <div className="m-4081bf90 mantine-Group-root">
                            <button className='m-77c9d27d'>
                              <div className="m-80f1301b">
                                <div className="m-811560b9">
                                  Max
                                </div>
                              </div>
                            </button>
                            <div className="mantine-focus-auto m-b6d8b162 mantine-Text-root" >
                              {
                                BalanceLoading
                                  ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: 'rgb(177 155 155)' }} spin />} />
                                  : selectConvertOrd === '1' ?
                                    !!balanceCount ? balanceCount : 0
                                    :
                                    formData.from.type === 'Magnet' ?
                                      !!balance ? balance : 0
                                      : !!balanceCount ? balanceCount : 0
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

              </Form.Item>
            </Col>
            <Col span={2}></Col>
            <Col span={11} >
              <div
                style={{
                  padding: '25px'
                }}
              >
                <Form.Item style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: "center",
                }}>
                  <Button
                    className={['send', sendLoading ? 'loading' : ''].join(' ')}
                    type="primary"
                    htmlType="submit"
                    disabled={formData.count <= 0 || selectSubWallet === undefined || accountAddress === ''}

                    onClick={onFinish}
                  >
                    {sendLoading ? <div className="loading-icon">
                      <div className="lds-spinner">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                      </div>
                    </div> : 'Send'}


                  </Button>

                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }

  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let interval: string | number | NodeJS.Timer | undefined;

    if (isRunning) {
      interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds + 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isRunning]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const toggleTimer = () => {
    setIsRunning(prevIsRunning => !prevIsRunning);
  };


  const CustomStepContent2 = () => {
    return (
      <div className='step2'>
        <div className="container">
          <div className="top">
            <div className="content">
              <div className="lds-spinner">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
              </div>
              <p>Transaction completed!</p>
            </div>
          </div>
          <div className="center">
            <div className="row">
              <div className="lable">
                Summary
              </div>
              <div className="gutter">
                <div className="gutter-row">
                  <div className='time-box box'>
                    <div className="time">
                      <p className='mb20'>Time</p>
                      <div>
                        {formatTime(seconds)}
                      </div>
                    </div>
                    <div className="coast">
                      <p className='mb20'>Overall cost</p>
                      <p>${((formData.count + gasfreeValue) * dotPriceVal).toFixed(5)}</p>
                    </div>
                  </div>
                </div>
                <div className="gutter-row">
                  <div className='amount box'>
                    <p className='mb20'>Amount</p>
                    <p>{formData.count}DOT</p>
                  </div>
                </div>
                <div className="gutter-row">
                  <div className='add box'>
                    <p className='mb20'>Add to MetaMask</p>
                    <div className="walletList">
                      <div className="item">
                        <img src={require('../../assets/metamask.svg').default} width='100%' alt="" />
                      </div>
                      <div className="add-btn">
                        <img src={require('../../assets/add.png')} width='100%' alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row info">
              <div className="lable-box">
                <div className="network">
                  Network
                </div>
                <div className="Status">
                  Status
                </div>
                {/* <div className="loading">
                  <div className="Finish" style={{ visibility: 'hidden' }}>
                    <img src={require('../../assets/finish.png')} alt="" width='24' />
                  </div>
                </div> */}
                <div className="loading">
                  <div className="lds-spinner" style={{ visibility: 'hidden', height: '0' }}>
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                  </div>
                </div>
                <div className="Timer">
                  Timer
                </div>
                <div className="gas">
                  Cost of Gas
                </div>
              </div>
              <div className="content-box">
                <div className="network">
                  <div className='icon'>
                    <img src={require('../../assets/dot_logo.png')} alt="" width='24' />
                    <p>{selectConvertOrd === '2' ? formData.from.type === 'Magnet' ? 'DOT-EVM' : 'DOT-Magnet' : formData.from.type}</p>
                  </div>
                  <div className="address">
                    <p className='from'>From</p>
                    <p>
                      {
                        selectConvertOrd === '1'
                          ?

                          !!selectSubWallet ? `${selectSubWallet.address.slice(0, 7)}...${selectSubWallet.address.slice(-5)}` : "Not connected"
                          : formData.from.type === 'Magnet'
                            ? !!accountAddress ? `${accountAddress.slice(0, 7)}...${accountAddress.slice(-5)}` : "Not connected"
                            : !!selectSubWallet ? `${selectSubWallet.address.slice(0, 7)}...${selectSubWallet.address.slice(-5)}` : "Not connected"
                      }
                    </p>
                  </div>
                </div>
                <div className="Status">
                  FINALIZED
                </div>
                <div className="loading">
                  <div className="lds-spinner">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                  </div>
                </div>
                <div className="Timer">
                  {formatTime(seconds)}
                </div>
                <div className="gas">
                  <p>{gasfreeValue}dot</p>
                  <p>${gasfreeValue * dotPriceVal}</p>
                </div>
              </div>
              <div className="content-box Recipient-info">
                <div className="network">
                  <div className='icon'>
                    <img src={require('../../assets/dot_logo.png')} alt="" width='24' />
                    <p>


                      {selectConvertOrd === '2' ? formData.to.type === 'Magnet' ? 'DOT-EVM' : 'DOT-Magnet' : formData.to.type}

                    </p>
                  </div>
                  <div className="address">
                    <p className='from'>To</p>
                    <p>
                      {
                        selectConvertOrd === '1'
                          ?

                          !!selectSubWallet ? `${selectSubWallet.address.slice(0, 7)}...${selectSubWallet.address.slice(-5)}` : "Not connected"
                          : formData.to.type === 'Magnet'
                            ? !!accountAddress ? `${accountAddress.slice(0, 7)}...${accountAddress.slice(-5)}` : "Not connected"
                            : !!selectSubWallet ? `${selectSubWallet.address.slice(0, 7)}...${selectSubWallet.address.slice(-5)}` : "Not connected"
                      }
                    </p>
                  </div>
                </div>
                <div className="Status">
                  FINALIZED
                </div>
                <div className="loading">
                  <div className="lds-spinner">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                  </div>
                </div>
                <div className="Timer">
                  {formatTime(seconds)}
                </div>
                <div className="gas">
                  <p>{gasfreeValue}dot</p>
                  <p>${gasfreeValue * dotPriceVal}</p>
                </div>
              </div>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: "center",
            }}>
              <Button
                onClick={() => {
                  setCurrent(0)
                  setSeconds(0)
                  getAccount()
                  getSubBalance(selectSubWallet.address, formData.from.type)
                }}
                className='Bridge-again' type="primary" htmlType="submit">
                Bridge again
              </Button>

            </div>
          </div>

        </div>
      </div>
    );
  }
  const CustomStepContent3 = () => {
    return (
      <div className='step2'>
        <div className="container">
          <div className="top">
            <div className="content">
              <div className="Finish">
                <img src={require('../../assets/finish.png')} alt="" width='24' />
              </div>
              <p>Transaction completed!</p>
            </div>
          </div>
          <div className="center">
            <div className="row">
              <div className="lable">
                Summary
              </div>
              <div className="gutter">
                <div className="gutter-row">
                  <div className='time-box box'>
                    <div className="time">
                      <p className='mb20'>Time</p>
                      <div>
                        {formatTime(seconds)}
                      </div>
                    </div>
                    <div className="coast">
                      <p className='mb20'>Overall cost</p>
                      <p>${((formData.count + gasfreeValue) * dotPriceVal).toFixed(5)}</p>
                    </div>
                  </div>
                </div>
                <div className="gutter-row">
                  <div className='amount box'>
                    <p className='mb20'>Amount</p>
                    <p>{formData.count}DOT</p>
                  </div>
                </div>
                <div className="gutter-row">
                  <div className='add box'>
                    <p className='mb20'>Add to MetaMask</p>
                    <div className="walletList">
                      <div className="item">
                        <img src={require('../../assets/metamask.svg').default} width='100%' alt="" />
                      </div>
                      <div className="add-btn">
                        <img src={require('../../assets/add.png')} width='100%' alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row info">
              <div className="lable-box">
                <div className="network">
                  Network
                </div>
                <div className="Status">
                  Status
                </div>
                <div className="loading">
                  <div className="Finish" style={{ visibility: 'hidden' }}>
                    <img src={require('../../assets/finish.png')} alt="" width='24' />
                  </div>
                </div>
                <div className="Timer" style={{ textAlign: 'center' }}>
                  Timer
                </div>
                <div className="gas">
                  Cost of Gas
                </div>
              </div>
              <div className="content-box">
                <div className="network">
                  <div className='icon'>
                    <img src={require('../../assets/dot_logo.png')} alt="" width='24' />
                    <p>
                      {selectConvertOrd === '2' ? formData.from.type === 'Magnet' ? 'DOT-EVM' : 'DOT-Magnet' : formData.from.type}
                    </p>
                  </div>
                  <div className="address">
                    <p className='from'>From</p>
                    <p>
                      {
                        selectConvertOrd === '1'
                          ?

                          !!selectSubWallet ? `${selectSubWallet.address.slice(0, 7)}...${selectSubWallet.address.slice(-5)}` : "Not connected"
                          : formData.from.type === 'Magnet'
                            ? !!accountAddress ? `${accountAddress.slice(0, 7)}...${accountAddress.slice(-5)}` : "Not connected"
                            : !!selectSubWallet ? `${selectSubWallet.address.slice(0, 7)}...${selectSubWallet.address.slice(-5)}` : "Not connected"
                      }
                    </p>
                  </div>
                </div>
                <div className="Status">
                  FINALIZED
                </div>
                <div className="loading">
                  <div className="Finish">
                    <img src={require('../../assets/finish.png')} alt="" width='24' />
                  </div>
                </div>
                <div className="Timer">
                  {formatTime(seconds)}
                </div>
                <div className="gas">
                  <p>{gasfreeValue}dot</p>
                  <p>${gasfreeValue * dotPriceVal}</p>
                </div>
              </div>
              <div className="content-box Recipient-info">
                <div className="network">
                  <div className='icon'>
                    <img src={require('../../assets/dot_logo.png')} alt="" width='24' />
                    <p>  {selectConvertOrd === '2' ? formData.to.type === 'Magnet' ? 'DOT-Magnet' : 'DOT-EVM' : formData.to.type}</p>
                  </div>
                  <div className="address">
                    <p className='from'>To</p>
                    <p>
                      {
                        selectConvertOrd === '1'
                          ?

                          !!selectSubWallet ? `${selectSubWallet.address.slice(0, 7)}...${selectSubWallet.address.slice(-5)}` : "Not connected"
                          : formData.to.type === 'Magnet'
                            ? !!accountAddress ? `${accountAddress.slice(0, 7)}...${accountAddress.slice(-5)}` : "Not connected"
                            : !!selectSubWallet ? `${selectSubWallet.address.slice(0, 7)}...${selectSubWallet.address.slice(-5)}` : "Not connected"
                      }
                    </p>
                  </div>
                </div>
                <div className="Status">
                  FINALIZED
                </div>
                <div className="loading">
                  <div className="Finish">
                    <img src={require('../../assets/finish.png')} alt="" width='24' />
                  </div>
                </div>
                <div className="Timer">
                  {formatTime(seconds)}
                </div>
                <div className="gas">
                  <p>{gasfreeValue}dot</p>
                  <p>${gasfreeValue * dotPriceVal}</p>
                </div>
              </div>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: "center",
            }}>
              <Button
                onClick={() => {
                  setCurrent(0)
                  setSeconds(0)
                  getAccount()
                  getSubBalance(selectSubWallet.address, formData.from.type)
                }}
                className='Bridge-again' type="primary" htmlType="submit">
                Bridge again
              </Button>

            </div>
          </div>

        </div>
      </div>
    );
  }

  const steps = [
    {
      title: 'STEP.1',
      subTitle: 'Choose asset,chains and address',
      description: <CustomStepContent1 />
    },
    {
      title: 'STEP.2',
      subTitle: 'Check transaction status    ',
      description: <CustomStepContent2 />
    },
    {
      title: 'STEP.3',
      subTitle: 'Transfer complete    ',
      description: <CustomStepContent3 />
    },
  ];

  const [current, setCurrent] = useState(0);


  const StepItems = steps.map((item) => (
    { key: item.title, title: item.title, subTitle: item.subTitle }
  ));


  const [activeEVMButton, setActiveEVMButton] = useState(null);
  const [activeSubButton, setActiveSubButton] = useState(null);

  const handleEVMButtonClick = (buttonId: any) => {
    setActiveEVMButton(buttonId);
    connectButtonOnClick()
    closeWalletModel()
  };


  const [showWalletOpen, setShowWalletOpen] = useState(false)

  const showWalletModel = () => {
    gsap.fromTo('.wallet_model', { opacity: 0, zIndex: -19999, display: 'none' }, { opacity: 1, zIndex: 1001, display: 'block', duration: .3 })
    setShowWalletOpen(true)
  }
  const closeWalletModel = () => {
    gsap.fromTo('.wallet_model', { opacity: 1, zIndex: 1001, display: 'block' }, { opacity: 0, zIndex: -9999, display: 'none', duration: .3 })
    setShowWalletOpen(false)
  }

  document.addEventListener('click', function (event) {
    event.stopPropagation()
    // 获取点击的目标元素
    const targetElement = event.target;
    setShowWalletOpen(false)
    // 检查点击的元素是否是 class 为 "asd" 的 div 或其子元素
    // @ts-ignore
    if (!targetElement.closest('.ConnectWallet_dropdown')) {
      // 如果不是，执行 alert(1)
      gsap.to('.wallet_model', { opacity: 0, zIndex: -19999, display: 'none' })
    }
  });
  const [, setPopoverOpen] = useState(false);

  const popHide = () => {
    setPopoverOpen(false);
  };

  const handlepopOpenChange = (newOpen: boolean) => {
    setPopoverOpen(newOpen);
  };

  const SubWalletChange = async (type: number, item: any) => {

    console.log('%c🀁 ', 'color: #ff0000; font-size: 20px;', 1231231231231231);
    try {
      setSelectSunWallet(item);
      // const api = await connectToApi8854();
      // //@ts-ignore
      // const { data: balance } = await api.query.system.account(selectSubWallet.address);
      // console.log(`账户余额: ${balance.free.toHuman()}`);

      if (type === 0) {
        popHideAddress();
      }
      else if (type === 1) {
        popHide();
      }
      closeWalletModel();
      await getSubBalance(item.address)
      // api.disconnect()
    } catch (error) {
      console.log('%c🀅 error', 'color: #514080; font-size: 20px;', error);

    }
  }
  // 设置连接钱包弹窗中的波卡地址选择
  const [, setPopoverOpenAddress] = useState(false);

  const popHideAddress = () => {
    setPopoverOpenAddress(false);
  };

  const handlepopAddressOpenChange = (newOpen: boolean) => {
    setPopoverOpenAddress(newOpen);
  };
  // 连接钱包弹窗中的波卡地址选择内容
  const PopoverFromContentAddress = _.map(SUBAccounts, (item) => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px 5px',
        cursor: 'pointer'
      }}
        onClick={async () => {
          SubWalletChange(0, item)
          await getSubBalance(item)
          popHideAddress()
        }}
        key={item.address}
      >
        <div className="paper" >

          <svg xmlns="http:www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
              fill="#fc3900">
            </rect>
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
              fill="#f16402">
            </rect>
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
              fill="#035d55">
            </rect>
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
              fill="#f38800">
            </rect>
          </svg>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          <div className='name'>{item.meta.name}</div>
          <div className='address'>{`${item.address.slice(0, 15)}...${item.address.slice(-15)}`}</div>
        </div>
      </div>
    )
  })

  // 连接钱包弹窗中的波卡地址选择内容
  const PopoverContentAddress = _.map(SUBAccounts, (item) => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px 5px',
        cursor: 'pointer'
      }}
        onClick={() => {
          SubWalletChange(0, item)
          popHideAddress()
        }}
        key={item.address}
      >
        <div className="paper" >

          <svg xmlns="http:www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
              fill="#fc3900">
            </rect>
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
              fill="#f16402">
            </rect>
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
              fill="#035d55">
            </rect>
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
              fill="#f38800">
            </rect>
          </svg>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          <div className='name'>{item.meta.name}</div>
          <div className='address'>{`${item.address.slice(0, 15)}...${item.address.slice(-15)}`}</div>
        </div>
      </div>
    )
  })


  const PopoverContent = _.map(SUBAccounts, (item) => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px 5px',
        cursor: 'pointer'
      }}
        onClick={() => { SubWalletChange(1, item) }}
        key={item.address}
      >
        <div className="paper" >

          <svg xmlns="http:www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
              fill="#fc3900">
            </rect>
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
              fill="#f16402">
            </rect>
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
              fill="#035d55">
            </rect>
            <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
              fill="#f38800">
            </rect>
          </svg>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          <div className='name'>{item.meta.name}</div>
          <div className='address'>{`${item.address.slice(0, 15)}...${item.address.slice(-15)}`}</div>
        </div>
      </div>
    )
  })

  const [selectConvertOrd, setSelectConvertOrd] = useState('1')
  const electConvertOrdRef = useRef('1')



  const addBodyClass = (className: string) => {
    document.body.classList.add(className);
  };

  const removeBodyClass = (className: string) => {
    document.body.classList.remove(className);
  };


  return (
    <>
      <div className="bridgesView">
        <div className="header">
          <div className="logo">
            <img src={require('../../assets/logo_w.png')} alt="" />
          </div>
          <div className="nav">
            <a href="/assets">Assets</a>
            <a href="/bridges" className='active'>Parachain Bridges</a>
          </div>
          {
            disconnect
              ? <div className="walletInfo" style={{ display: 'none' }}>
                <div className="m-4081bf90 mantine-Group-root">
                  <div className="paper" >
                    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="100%" width="100%">
                      <rect x="0" y="0" rx="0" ry="0" height="35" width="35" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                        fill="#fc3900">
                      </rect>
                      <rect x="0" y="0" rx="0" ry="0" height="35" width="35" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                        fill="#f16402">
                      </rect>
                      <rect x="0" y="0" rx="0" ry="0" height="35" width="35" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                        fill="#035d55">
                      </rect>
                      <rect x="0" y="0" rx="0" ry="0" height="35" width="35" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                        fill="#f38800">
                      </rect>
                    </svg>
                  </div>
                  <div className="address">
                    <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                      {!!accountAddress ? `${accountAddress.slice(0, 10)}...${accountAddress.slice(-5)}` : "Not connected"}
                    </p>
                    <button className='Disconnect'>{!accountAddress ? 'Disconnect' : 'Connected'}</button>
                  </div>
                  <span className="m-80f1301b mantine-Button-inner">
                    <span className="m-811560b9 mantine-Button-label"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showWalletOpen) {
                          closeWalletModel()
                        } else {
                          showWalletModel()
                        }
                      }}
                    >
                      <svg
                        stroke="#fff"
                        fill="#fff"
                        strokeWidth={0}
                        viewBox="0 0 512 512"
                        className="ConnectWallet_openWalletIcon__s1LVr"
                        height=".4em"
                        width=".4em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="none"
                          strokeLinecap="square"
                          strokeMiterlimit={10}
                          strokeWidth={48}
                          d="M112 184l144 144 144-144"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
              </div>
              : <div className="wallet" style={{ display: 'none' }}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (showWalletOpen) {
                      closeWalletModel()
                    } else {
                      showWalletModel()
                    }
                  }}
                >
                  <img src={require('../../assets/wallet.png')} alt="" />
                  <span>Connect wallet</span>
                </button>
              </div>
          }

        </div>
        <div className="main">
          <div className="content">

            <Tabs
              defaultActiveKey="1"
              className='converTabs'
              activeKey={selectConvertOrd}
              tabBarGutter={16}
              type="card"
              onTabClick={async (key) => {
                setSelectConvertOrd(key)
                electConvertOrdRef.current = key
                if (key === '2') {
                  // showBindModal()
                  addBodyClass('model-evm')


                } else {
                  removeBodyClass('model-evm')
                  // if (selectSubWallet) {
                  //   await getSubBalance(selectSubWallet.address, undefined)
                  // }
                }
                if (selectSubWallet) {
                  await getSubBalance(selectSubWallet.address, undefined)
                }
                setCurrent(0)
              }}>
              <TabPane tab="Magnet -> Relaychain" key="1">
                <Steps className='mystep' current={current} items={StepItems} />
                <div className='stepContent'>{steps[current].description}</div>

              </TabPane>
              <TabPane tab="Magnet -> EVM" key="2">
                <div className="evm_content">
                  <Steps className='mystep' current={current} items={StepItems} />
                  <div className='stepContent'>{steps[current].description}</div>

                </div>
              </TabPane>
            </Tabs>
          </div>

        </div>
      </div>
      <div className="wallet_model" >
        <div className="ConnectWallet_dropdown">
          <div className="">
            <div className="mantine-Paper-root">
              <h4 className=" mantine-Title-root">Networks</h4>
              <div className="icon">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path fill="none" strokeMiterlimit="10" strokeWidth="32" d="M248 64C146.39 64 64 146.39 64 248s82.39 184 184 184 184-82.39 184-184S349.61 64 248 64z"></path>
                  <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M220 220h32v116"></path>
                  <path fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M208 340h88"></path>
                  <path d="M248 130a26 26 0 1026 26 26 26 0 00-26-26z"></path>
                </svg>
              </div>
              <div className=" mantine-Group-root">
                <div className="MoonChainButton_root__m3P0N">
                  <button className="mantine-focus-auto MoonChainSection_root__JetMm MoonChainButton_button__181Bc MoonChainButton_selected__mFVtD  mantine-UnstyledButton-root" type="button">
                    <div className=" mantine-Group-root">
                      {/* <img alt="Moonbeam" src={require('../../assets/moonbeam-logo.webp')} width="116" height="24" decoding="async" data-nimg="future" /> */}
                      <p>Magnet</p>
                      <p className="mantine-focus-auto m-b6d8b162 mantine-Text-root" data-size="xs" >Selected</p>
                    </div></button>

                </div>
                {/* <div className="MoonChainButton_root__m3P0N">
                  <button className="mantine-focus-auto MoonChainSection_root__JetMm MoonChainButton_button__181Bc  mantine-UnstyledButton-root" type="button">
                    <div className="m-4081bf90 mantine-Group-root" >
                      <img alt="Moonbeam" src={require('../../assets/moonriver-logo.webp')} width="116" height="24" decoding="async" data-nimg="future" />
                    </div></button>

                </div>
                <div className="MoonChainButton_root__m3P0N">
                  <button className="mantine-focus-auto MoonChainSection_root__JetMm MoonChainButton_button__181Bc m-87cf2631 mantine-UnstyledButton-root" type="button">
                    <div className="m-4081bf90 mantine-Group-root" >
                      <img alt="Moonbeam" src={require('../../assets/moonbase-logo.webp')} width="116" height="24" decoding="async" data-nimg="future" />
                    </div></button>

                </div> */}
              </div>
            </div>
            <Tabs centered defaultActiveKey="1" activeKey={selectTabsOrd} tabBarGutter={16} type="card" onTabClick={(key) => {
              setSelectTabsOrd(key)
            }}>
              <TabPane tab="EVM wallet" key="1">
                <div className="tabcontent">
                  <div className=" mantine-Group-root">
                    <div className="MoonChainButton_root__m3P0N">
                      <button
                        className={`mantine-focus-auto mantine-active theme_button__Gl8qQ WalletButton_button__8SwN_ m-77c9d27d mantine-Button-root m-87cf2631 mantine-UnstyledButton-root ${activeEVMButton === 1 ? 'WalletButton_selected__AKZ36' : ''}`}
                        onClick={() => handleEVMButtonClick(1)}
                        type="button" data-testid="wallet-MetaMask-button">
                        <span className="WalletButton_inner__CItsG m-80f1301b mantine-Button-inner">
                          <span className="WalletButton_rightIcon__8DZrY m-a74036a mantine-Button-section"
                            data-position="left">
                            <div className="" >
                              <img alt="MetaMask"
                                src={require('../../assets/metamask.svg').default} width="24" height="24"
                                decoding="async" data-nimg="future" loading="lazy" />
                            </div>
                          </span>
                          <span className="WalletButton_label__ipoVW m-811560b9 mantine-Button-label">
                            MetaMask
                          </span>
                        </span>
                      </button>

                    </div>
                    {/* <div className="MoonChainButton_root__m3P0N">
                      <button
                        className={`mantine-focus-auto mantine-active theme_button__Gl8qQ WalletButton_button__8SwN_ m-77c9d27d mantine-Button-root m-87cf2631 mantine-UnstyledButton-root ${activeEVMButton === 2 ? 'WalletButton_selected__AKZ36' : ''}`}
                        onClick={() => handleEVMButtonClick(2)}
                        type="button" data-testid="wallet-Talisman-button">
                        <span className="WalletButton_inner__CItsG m-80f1301b mantine-Button-inner">
                          <span className="WalletButton_rightIcon__8DZrY m-a74036a mantine-Button-section"
                            data-position="left">
                            <div className="" >
                              <img alt="talisman"
                                src={require('../../assets/talisman.svg').default} width="24" height="24"
                                decoding="async" data-nimg="future" loading="lazy" />
                            </div>
                          </span>
                          <span className="WalletButton_label__ipoVW m-811560b9 mantine-Button-label">
                            Talisman
                          </span>
                        </span>
                      </button>

                    </div> */}

                  </div>
                  <div className="account_box" >
                    <label className="mantine-focus-auto m-b6d8b162 mantine-Text-root" data-size="sm"
                    >
                      Account
                    </label>
                    <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                    >
                      <div className="m-4081bf90 mantine-Group-root">
                        <div className="" >
                          <div className="paper" >
                            <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                fill="#fc3900">
                              </rect>
                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                fill="#f16402">
                              </rect>
                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                fill="#035d55">
                              </rect>
                              <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                fill="#f38800">
                              </rect>
                            </svg>
                          </div>
                        </div>
                        <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                          {!!accountAddress ? `${accountAddress.slice(0, 10)}...${accountAddress.slice(-8)}` : "Not connected"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Substrate wallet" key="2">
                <div className="tabcontent">
                  <div className=" mantine-Group-root">
                    <div className="MoonChainButton_root__m3P0N">
                      <button
                        className={`mantine-focus-auto mantine-active theme_button__Gl8qQ WalletButton_button__8SwN_ m-77c9d27d mantine-Button-root m-87cf2631 mantine-UnstyledButton-root ${activeSubButton === 1 ? 'WalletButton_selected__AKZ36' : ''}`}
                        onClick={() => handleSUBButtonClick(1)}
                        data-variant="light" data-with-left-section="true" type="button" data-testid="wallet-Talisman-button">
                        <span className="WalletButton_inner__CItsG m-80f1301b mantine-Button-inner">
                          <span className="WalletButton_rightIcon__8DZrY m-a74036a mantine-Button-section"
                            data-position="left">
                            <div className="" >
                              <img alt="talisman"
                                src={require('../../assets/polkadot.svg').default} width="24" height="24"
                                decoding="async" data-nimg="future" loading="lazy" />
                            </div>
                          </span>
                          <span className="WalletButton_label__ipoVW m-811560b9 mantine-Button-label">
                            Polkadot
                          </span>
                        </span>
                      </button>

                    </div>

                  </div>
                  <div className="account_box" >
                    <label className="mantine-focus-auto m-b6d8b162 mantine-Text-root" data-size="sm"
                    >
                      Account
                    </label>

                    {
                      SUBAccounts.length === 0
                        ? <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                        > <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                            Not connected
                          </p></div>
                        : SUBAccounts.length === 1
                          ? <div className="InputlikeText_group__cQxF2 m-4081bf90 mantine-Group-root"
                          ><div className="m-4081bf90 mantine-Group-root">
                              <div className="" >
                                <div className="paper" >
                                  <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                    <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                      fill="#fc3900">
                                    </rect>
                                    <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                      fill="#f16402">
                                    </rect>
                                    <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                      fill="#035d55">
                                    </rect>
                                    <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                      fill="#f38800">
                                    </rect>
                                  </svg>
                                </div>
                              </div>
                              <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                {SUBAccounts[0].meta.name}
                              </p>
                            </div>
                          </div>
                          : <Popover content={PopoverContent}
                            // open={popoverOpen}
                            onOpenChange={handlepopOpenChange}
                            className="subWalletPopover" trigger="click" >
                            <Button style={{
                              display: 'block',
                              width: '100%'
                            }}>

                              <div className="m-4081bf90 mantine-Group-root" style={{
                                justifyContent: 'flex-start'
                              }}>
                                <div className="" >
                                  <div className="paper" >
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" height="24" width="24">
                                      <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-0.668012256532851 0.9363136097729164) rotate(277.6 12 12)"
                                        fill="#fc3900">
                                      </rect>
                                      <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(10.8968080370008 8.052020358176973) rotate(105.0 12 12)"
                                        fill="#f16402">
                                      </rect>
                                      <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(18.836660583474604 -3.1474031752570557) rotate(405.8 12 12)"
                                        fill="#035d55">
                                      </rect>
                                      <rect x="0" y="0" rx="0" ry="0" height="24" width="24" transform="translate(-12.063796073919118 -22.224818771606362) rotate(341.9 12 12)"
                                        fill="#f38800">
                                      </rect>
                                    </svg>
                                  </div>
                                </div>
                                <p className="mantine-focus-auto InputlikeText_text__b2CLH m-b6d8b162 mantine-Text-root">
                                  { }
                                  {selectSubWallet !== undefined ? selectSubWallet.meta.name : SUBAccounts[0].meta.name}
                                </p>
                              </div>
                            </Button>
                          </Popover>
                    }
                  </div>
                </div>
              </TabPane>
            </Tabs>


          </div>
        </div>
      </div>
    </>
  );
}

export default Bridges;
