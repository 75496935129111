import axios from "axios";

const getDotPrice = async () => {
  try {
    const response = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price",
      {
        params: {
          ids: "polkadot",
          vs_currencies: "usd",
        },
      }
    );

    const price = response.data.polkadot.usd;
    console.log(`Polkadot (DOT) 价格: $${price}`);
    return price;
  } catch (error) {
    console.error("获取 Polkadot 价格时发生错误:", error);
    throw error;
  }
};

export default getDotPrice;
