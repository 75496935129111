import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';

const MetaMaskIntegration: React.FC = () => {
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [isWalletConnected, setIsWalletConnected] = useState<boolean>(false);
  const [walletBalance, setWalletBalance] = useState<string>('');

  useEffect(() => {
    const initializeMetaMask = async () => {
      if (window.ethereum) {
        try {
          // 请求账户访问权限
          //@ts-ignore
          await window.ethereum.send('eth_requestAccounts');

          // 监听账户变化
          //@ts-ignore
          window.ethereum.on('accountsChanged', (accounts: string[]) => {
            setWalletAddress(accounts[0]);
          });

          // 监听钱包连接状态变化
          //@ts-ignore
          window.ethereum.on('chainChanged', () => {
            checkWalletConnection();
          });

          // 检查钱包连接状态
          checkWalletConnection();
        } catch (error) {
          console.error('MetaMask连接失败:', error);
        }
      } else {
        console.error('MetaMask 不可用');
      }
    };

    const checkWalletConnection = async () => {
      //@ts-ignore
      const isConnected = window.ethereum.isConnected();
      setIsWalletConnected(isConnected);

      if (isConnected) {
        //@ts-ignore
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);

        const balance = await provider.getBalance(address);
        const formattedBalance = ethers.utils.formatEther(balance);
        setWalletBalance(formattedBalance);
      } else {
        setWalletAddress('');
        setWalletBalance('');
      }
    };

    initializeMetaMask();

    return () => {
      // 清理监听器
      if (window.ethereum) {
        //@ts-ignore
        window.ethereum.removeAllListeners('accountsChanged');
        //@ts-ignore
        window.ethereum.removeAllListeners('chainChanged');
      }
    };
  }, []);

  const handleSignMessage = async () => {
    if (window.ethereum) {
      try {
        const message = 'Hello, world!';
        //@ts-ignore
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const signature = await signer.signMessage(message);
        console.log('签名:', signature);
      } catch (error) {
        console.error('签名失败:', error);
      }
    }
  };
  const handleDisconnectWallet = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: 'wallet_requestPermissions', params: [{ eth_accounts: {} }] })
        .then(() => {
          setIsWalletConnected(false);
          setWalletAddress('');
          setWalletBalance('');
          console.log('已断开 MetaMask 连接');
        })
        .catch((error) => {
          console.error('断开连接失败:', error);
        });
    }

  };

  return (
    <div>
      <h1>MetaMask 集成示例</h1>
      <p>钱包地址: {walletAddress}</p>
      <p>钱包连接状态: {isWalletConnected ? '已连接' : '未连接'}</p>
      <p>账户余额: {walletBalance} ETH</p>
      {isWalletConnected && (
        <button onClick={handleDisconnectWallet}>断开连接</button>
      )}
      <button onClick={handleSignMessage}>执行授权签名</button>
    </div>
  );
};

export default MetaMaskIntegration;
