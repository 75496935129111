import './index.scss'
import React from 'react';

const MobileHome = (): JSX.Element => {
  return (
    <>
      <div className="mpage">
        <div className="header">
          <div className="logo">
            <img src={require('../../assets/logo.png')} alt="" />
          </div>
          <div className="nav">
            {/* <div className="item langu">
              <img src={require('../../assets/changLanguage.png')} alt="" />
            </div> */}
            <button
              onClick={() => {
                const dom = document.getElementById('footer');
                if (dom) {
                  dom.scrollIntoView({ behavior: "smooth", });
                }
              }}
              className="contact">Contact Us</button>
          </div>
        </div>
        <div className="banner">
          <div className="logo">
            <img src={require('../../assets/banner-icon.png')} alt="" width={'100%'} />
          </div>
          <h2>The Smart Contract Expansion
            Dock Utilizing DOT as Gas Fee</h2>
          <a className="download" href={require('../../assets/pdfs/MAGLightpaper.pdf')} download> Introducing MCP</a>
        </div>
        <div className={['project'].join(' ')} id="project">
          {/* <h2>Project Characteristics</h2> */}
          <div className="project-main">
            <div className="project-list">
              <div className="item">
                <div className="ord active">
                  01
                </div>
                <div className="content">
                  <h3>DOT as the native token</h3>
                  <p> Magnet operates as a token-less chain, with all token functionalities carried out by DOT obtained through XCM.</p>
                </div>
              </div>

              <div className="item nextActive">
                <div className="ord">
                  02
                </div>
                <div className="content">
                  <h3>Flexible and fast access through PAYG</h3>
                  <p> Magnet utilizes the PAYG model to quickly and flexibly order blockspace and access the Polkadot network.</p>
                </div>
              </div>

              <div className="item">
                <div className="ord">
                  03
                </div>
                <div className="content">
                  <h3>Sustainable operation through gas fee revenue</h3>
                  <p> Magnet collects gas fees on its network and uses them to purchase the necessary blocks. The difference between the actual ordering price and the obtained gas fees becomes the Magnet's revenue, which will be allocated to the Polkadot treasury, system reserves, collator incentives, and project operations.</p>
                </div>
              </div>

              <div className="item">
                <div className="ord">
                  04
                </div>
                <div className="content">
                  <h3>Support for EVM and WASM</h3>
                  <p> Initially, Magnet supports EVM to rapidly acquire and accumulate users. Later, it will also support WASM to prepare for the future high-performance smart contract market.</p>
                </div>
              </div>

              <div className="item">
                <div className="ord">
                  05
                </div>
                <div className="content">
                  <h3>Governance delegated to DOT holders</h3>
                  <p> Magnet's on-chain governance is entrusted to DOT holders, making DOT as the governance token of Magnet.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="moreList">
          <div className="item">
            <div className="ord">06</div>
            <div className="content">
              <h3>Enabling rapid parallel deployment through Polkadot Network</h3>
              <p>Due to Polkadot's Substrate architecture and Coretime design, Magnet can achieve efficient and cost-effective network access, enabling platform service scalability through parallel deployment.</p>
            </div>
            <div className="img">
              <img src={require('../../assets/pr7.gif')} alt="" className="bg" />
            </div>

          </div>
          <div className="item">
            <div className="ord">07</div>
            <div className="content">
              <h3>Collaboration with Tanssi Network for rapid development</h3>
              <p>Magnet leverages the development tools provided by Tanssi to enhance efficiency and enable quick implementation of logic. It also becomes a ContainerChain of the Tanssi Network, utilizing Tanssi Network's collators to assist in network formation. With the support of Tanssi and Polkadot, Magnet will achieve stable and secure operations, providing reliable services.</p>
            </div>
            <div className="img">
              <img src={require('../../assets/pr6.gif')} alt="" className="bg" />
            </div>

          </div>
          <div className="item before">
            <div className="ord">08</div>
            <div className="content">
              <h3>Inter docking station communication via XCM</h3>
              <p>Communication between different docking stations will be supported by XCM V3. The platform will develop XCM auxiliary tools to auxiliary cross-platform contract invocation.</p>
            </div>
            <div className="img">
              <img src={require('../../assets/pr8.gif')} alt="" className="bg" />
            </div>

          </div>
          <div className="item before">
            <div className="ord">09</div>
            <div className="content">
              <h3>Expansion mechanism utilizing PAYG and class-Rollup</h3>
              <p>With the support of trusted collators in Tanssi, Magnet combines the PAYG model to achieve a highly trusted class Rollup model. The containerchain can pre-generate multiple blocks and then order blockspace in a single batch, enabling fast transaction execution and improving blockspace utilization efficiency.</p>
            </div>
            <div className="img">
              <img src={require('../../assets/pr9.gif')} alt="" className="bg" />
            </div>

          </div>
        </div>
        <div className="footer" id='footer'>
          <div className="logo">
            <img src={require('../../assets/footer-logo.png')} alt="" height={'100%'} />
          </div>
          <div className="socialList">
            <div className="item">
              <a href="https://twitter.com/Magnet20239" target={'_blank'} rel="noreferrer" >
                <img src={require('../../assets/twitter.png')} alt="" />
              </a>
            </div>
            <div className="item">
              <a href="https://t.me/+aep298N0KXUwZTM1" target={'_blank'} rel="noreferrer" >
                <img src={require('../../assets/tg.png')} alt="" />
              </a>
            </div>
            <div className="item">
              <a href="mailto:magport@magport.io" target={'_blank'} rel="noreferrer" >
                <img src={require('../../assets/email.png')} alt="" />
              </a>
            </div>
            {/* <div className="item">
              <a href="#">
                <img src={require('../../assets/discord.png')} alt="" />
              </a>
            </div> */}
            {/* <div className="item">
              <a href="#">
                <img src={require('../../assets/github.png')} alt="" />
              </a>
            </div> */}
          </div>

          <div className="list">
            <div className="item">
              <img src={require('../../assets/tanssi_logo.png')} alt="" />
            </div>
            <div className="item">
              <img src={require('../../assets/polkadot_logo.png')} alt="" />
            </div>
          </div>
          {/* <div className="nav">
            <a href="#" className="item">terms of use</a>
            <a href="#" className="item">privacy policy</a>
          </div> */}
          <p>Copyright @ 2023 Magnet</p>
        </div>
      </div>
    </>
  );
}

export default MobileHome;


